import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  MenuItem as SelectMenuItem,
  Select,
  ListItemIcon,
  Divider,
  Box,
  IconButton,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getArguments = (obj, keyList) => {
  let args = [];
  for (let key of keyList) {
    args.push(obj[key]);
  }
  return args;
};

function BasicTable({ headers, rows, onUpdate, onDelete, loadCategory }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
 
  const [editedRow, setEditedRow] = useState({});
  const [modifiedRow, setModifiedRow] = useState([]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
    setModifiedRow(() =>
      rows.filter((category) => row?.name !== category.name)
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleUpdateClick = () => {
    setEditedRow(selectedRow);
    setOpenDialog(true);
    handleClose();
  };

 const handleDeleteClick = () => {
   
  setEditedRow(selectedRow);
   setOpenDeleteDialog(true);
   handleClose();
  };
  
  const handleDeleteConfirm = () => {
    if (onDelete && editedRow) {
      onDelete(editedRow);
    }
    setOpenDeleteDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditedRow({});
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedRow((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onUpdate(editedRow);
    handleDialogClose();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              {headers.map((header, i) => (
                <StyledTableCell width={header.width}>
                  <span>{header.label}</span>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {headers.map((header, i) => (
                  <StyledTableCell component="th" scope="row">
                    {header.type == "status" ? (
                      <div className="cell-label">
                        <CheckCircleIcon />{" "}
                        <span>{row[header.name] ? "Active" : "Inactive"}</span>
                      </div>
                    ) : header.type == "custom" ? (
                      header.component(
                        ...getArguments(row, header.arguments || [])
                      )
                    ) : header.type == "action" ? (
                      <MoreVertIcon
                        onClick={(event) => handleClick(event, row)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <span className="cell-text">{row[header.name]}</span>
                    )}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      sx={{
                        "& .MuiPaper-root": {
                          border: "0.5px solid #1876d2",
                          boxShadow: "none", // Orange color
                        },
                      }}
                    >
                      <MenuItem
                        sx={{
                          // Adjust padding as needed
                          fontSize: "16px", // Adjust font size
                        }}
                        onClick={handleUpdateClick}
                      >
                        Edit
                        <ListItemIcon sx={{ pl: 1 }}>
                          <EditIcon
                            fontSize="small"
                            sx={{ color: "#1876d2", fontSize: "18px" }}
                          />{" "}
                          {/* Edit icon */}
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "16px", // Adjust font size
                        }}
                        onClick={handleDeleteClick}
                      >
                        Delete
                        <ListItemIcon sx={{ pl: 1 }}>
                          <DeleteIcon
                            fontSize="small"
                            sx={{ color: "red", fontSize: "18px" }}
                          />{" "}
                          {/* Delete icon */}
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit Category
          <IconButton onClick={handleDialogClose} sx={{ color: "grey.500" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <TextField
            sx={{ pb: 2 }}
            size="small"
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            name="name"
            value={editedRow.name || ""}
            onChange={handleInputChange}
          />
          <TextField
            sx={{ pb: 2 }}
            size="small"
            margin="dense"
            multiline
            rows={4}
            label="Category Description"
            type="text"
            fullWidth
            variant="outlined"
            name="description"
            value={editedRow.description || ""}
            onChange={handleInputChange}
          />
          <FormControl fullWidth>
            <InputLabel>Category Parent</InputLabel>
            <Select
              size="small"
              name="parent_category_name"
              value={editedRow?.parent_category_name || ""}
              onChange={handleInputChange}
              variant="outlined"
              label="Category Parent"
              sx={{ mt: 1 }}
            >
              {modifiedRow.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <Divider />

        <Box display="flex">
          <Button
            variant="text"
            fullWidth
            sx={{ borderRadius: 0 }}
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ borderRadius: 0 }}
            onClick={handleSave}
            color="primary"
          >
            Update
          </Button>
        </Box>
      </Dialog>
       
        <Dialog  aria-labelledby="confirm-delete-dialog-title"
        
                aria-describedby="confirm-delete-dialog-description" open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
      <DialogTitle id="confirm-delete-dialog-title"  sx={{pb:0, fontSize:"1rem"}} >{"Delete Confirmation"}</DialogTitle>
      <DialogContent sx={{pt:0,pb:0}}>

      <DialogContentText sx={{pb:0, fontSize:"0.8rem"}} >{"(Please ensure that all products in this category are deleted.)"}</DialogContentText>
      </DialogContent>
      <Divider/>
      <DialogContent>

      <DialogContentText sx={{fontSize:"0.9rem"}} >{"Are you sure you want delete this category?"}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{p:"0 !important"}}>
        <Button sx={{width:"100%"}} fullWidth onClick={() => setOpenDeleteDialog(false)} color="primary">
          Cancel
        </Button>
        <Button sx={{width:"100%",borderRadius:0}} fullWidth onClick={handleDeleteConfirm} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default BasicTable;


