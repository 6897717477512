// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing {
  position: relative;
  /* Add any other styles you need */
}

.blurry-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-color: hsl(0deg 0% 100% / 36%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-content {
  text-align: center;
}

.blur-text {
  font-size: 24px;
  font-weight: bold;
  color: white; /* Adjust color as needed */
}
`, "",{"version":3,"sources":["webpack://./src/views/Landing/pricing.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,kCAA0B;UAA1B,0BAA0B;EAC1B,yCAAyC;EACzC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY,EAAE,2BAA2B;AAC3C","sourcesContent":[".pricing {\n  position: relative;\n  /* Add any other styles you need */\n}\n\n.blurry-background {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  backdrop-filter: blur(2px);\n  background-color: hsl(0deg 0% 100% / 36%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.blur-content {\n  text-align: center;\n}\n\n.blur-text {\n  font-size: 24px;\n  font-weight: bold;\n  color: white; /* Adjust color as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
