import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
    Card,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment,
    FormControlLabel,
    Switch,
    CardActions,
    CardContent,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

// import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";

import ImageUploader from 'components/Inputs/ImageUploader';
import CustomStepper from 'components/Stepper';

import PendingIcon from '@mui/icons-material/Pending';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BackpackIcon from '@mui/icons-material/Backpack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CloseIcon from '@mui/icons-material/Close';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { GLOBAL_CURRENCY } from 'constants/appConstants';

let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: GLOBAL_CURRENCY.code,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
});

const status = {
    1: 'Order Placed',
    2: 'Order Confirmed',
    3: 'Order Packed',
    4: 'Order Shipped',
    5: 'Order Delivered',
    6: 'Return Requested',
    7: 'Return Confirmed',
    8: 'Return Completed',
    10: 'Cancelled'
}

const defaultOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[2],
    id: 2,
    statusId: 2,
    icon: <ThumbUpAltIcon />
}, {
    label: status[3],
    id: 3,
    statusId: 3,
    icon: <BackpackIcon />
}, {
    label: status[4],
    id: 4,
    statusId: 4,
    icon: <LocalShippingIcon />
}, {
    label: status[5],
    id: 5,
    statusId: 5,
    icon: <CheckIcon />
}]

const cancelledOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[2],
    id: 2,
    statusId: 2,
    icon: <ThumbUpAltIcon />
}, {
    label: status[3],
    id: 3,
    statusId: 3,
    icon: <BackpackIcon />
}, {
    label: status[10],
    id: 4,
    statusId: 10,
    icon: <CloseIcon />
}]

const returnOrderCycle = [{
    label: status[1],
    id: 1,
    statusId: 1,
    icon: <PendingIcon />
}, {
    label: status[5],
    id: 2,
    statusId: 5,
    icon: <CheckIcon />
}, {
    label: status[6],
    id: 3,
    statusId: 6,
    icon: <HourglassBottomIcon />
}, {
    label: status[7],
    id: 4,
    statusId: 7,
    icon: <CheckIcon />
}, {
    label: status[8],
    id: 5,
    statusId: 8,
    icon: <AssignmentReturnIcon />
}]

const getOrderStatusCycle = (status) => {
    const defaultStatusList = [1, 2, 3, 4, 5]
    const returnStatusList = [6, 7, 8]
    const cancelStatusCycle = [9, 10]
    if (_.includes(defaultStatusList, status)) return defaultOrderCycle;
    if (_.includes(returnStatusList, status)) return returnOrderCycle;
    if (_.includes(cancelStatusCycle, status)) return cancelledOrderCycle;
}

const showName = (args) => {
    return _.compact(args).join(' ')
}

const showAddress = (address) => {
    return (
        <div className="address-wrapper">
            <span>{showName([address.first_name, address.last_name])} | {showName([address.country_code, address.phone_number])}</span><br />
            <Divider className='content-divider' />
            <span>{address.street_address}</span><br />
            <span>{address.city}</span><br />
            <span>{address.state}, {address.country} - {address.pin}</span>
        </div>
    )
}

const OrderDetails = ({
    discardUpdateOrder,
    orderId
}) => {
    const [loader, setLoader] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrderDetail] = useState({});
    const [orderCycle, setOrderCycle] = useState(defaultOrderCycle);

    const getOrderDetail = async (orderId) => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_ORDER(orderId))
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            setOrderDetail(data)
            console.log('getOrderStatusCycle(data.delivery_status) ', getOrderStatusCycle(data.delivery_status))
            if (data.delivery_status)
                setOrderCycle(getOrderStatusCycle(data.delivery_status))
        }
        setLoader(false)
    }

    useEffect(() => {
        if (orderId) {
            getOrderDetail(orderId)
        }
    }, [orderId])

    const getActiveStep = (steps, statusId) => {
        return _.get(_.find(steps, { statusId: statusId }), 'id', 1)
    }

    const updateOrderStatus = async (status) => {
        setLoader(true)
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId), {
            delivery_status: status
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            getOrderDetail(orderId)
        }
    }

    const cancelOrder = async (status) => {
        setLoader(true)
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_ORDER(orderId), {
            delivery_status: 10
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            getOrderDetail(orderId)
        }
    }


    return (
        <Grid container className='component-grid'>
            <Loader open={loader} />
            <Grid item xs={12}>
                <Card className='list-wrapper dashboard-card-wrapper'>
                    {!loader ?
                        <CardContent>
                            <div className='header-wrapper header-flex'>
                                <span className='header-text'>Order {`>`} {_.get(order, 'transaction_id')} </span>
                                {_.get(order, 'delivery_status') < 3 || _.get(order, 'delivery_status') == 10 ?
                                    <Button
                                        onClick={() => cancelOrder()}
                                        variant="outlined"
                                        color="error"
                                        disabled={_.get(order, 'delivery_status') == 10}
                                        size="small">
                                        {_.get(order, 'delivery_status') == 10 ? 'Cancelled' : 'Cancel Order'}
                                    </Button> : ''}
                            </div>
                            {/* <div className='header-wrapper'>
                        <span className='header-text'>Order {`>`} {_.get(order, 'order_id')} </span>
                    </div> */}
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    This is where you will be creating and managing your orders.
                                </span>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <div className='status-wrapper'>
                                    <CustomStepper
                                        steps={orderCycle}
                                        activeStep={getActiveStep(orderCycle, _.get(order, "delivery_status"))}
                                        completedStep={_.get(order, "delivery_status")} />
                                </div>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <Grid container className='dashboard-details-container' spacing={4} wrap='wrap'>
                                    <Grid item sm={12} className=''>
                                        <span>{_.get(order, "product_detail.name")} </span>
                                    </Grid>
                                    {_.get(order, 'product_detail.images') && order.product_detail.images.length > 0 ?
                                        <Grid item sm={3} className='image-grid'>
                                            <div className="image-container">
                                                <img src={order.product_detail.images[0]?.image} />
                                            </div>
                                        </Grid>
                                        : ''}
                                    <Grid item sm={9} className='order-detail-container'>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <span><b>Transaction Id : </b>{_.get(order, 'transaction_id')}</span>
                                                <br />
                                                <span><b>Order Id : </b>{_.get(order, 'order_id')}</span>
                                                <br />
                                                <span><b>Order Status : </b>{status[_.get(order, 'delivery_status')]}</span>
                                                <br />
                                                <span><b>Ordered On : </b>{moment(order.created).format("[On] ddd[,] D MMM")}</span>
                                                <br />
                                                <span><b>Quantity : </b>{order.quantity}</span>
                                                <br />
                                                <span><b>Available Stock : </b>{order.availableStock}</span>
                                                <br />
                                                {/* <span><b>Price : </b>{GLOBAL_CURRENCY.symbol}{order.unit_price}</span> */}
                                                <span><b>Price : </b>{formatCurrency.format(order.unit_price)}</span>
                                                <br />
                                                {!_.isEmpty(order?.payment_mode) ?
                                                    <div className='payment-mode-wrapper'>
                                                        <span>{order.payment_mode.payment_method_name}</span>
                                                        <span>{formatCurrency.format(order.quantity * order.unit_price)}</span>
                                                    </div> : ''}
                                                <Divider className='content-divider' />
                                                <span><b>Total Order Price : </b>{formatCurrency.format(order.total_price)}</span>
                                                <br />
                                                <span><b>Ordered by : </b>{_.get(order, 'customer_name')}</span>
                                                <br />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <span><b>Delivery Address :</b></span>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {showAddress(order.shipping_address)}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <span><b>Billing Address :</b></span>
                                            </Grid>
                                            <Grid item xs={9}>
                                                {showAddress(order.billing_address)}
                                                <br />
                                            </Grid>
                                            <Grid item xs={6} className='action-wrapper'>
                                                {order.delivery_status === 1 ?
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => {
                                                            updateOrderStatus(order.delivery_status + 1)
                                                        }}
                                                        color="primary">
                                                        Click to update this order as Confirmed
                                                    </Button>
                                                    : order.delivery_status === 2 ?
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => {
                                                                updateOrderStatus(order.delivery_status + 1)
                                                            }}
                                                            color="primary">
                                                            Click to update this order as Packed
                                                        </Button>
                                                        : order.delivery_status === 3 ?
                                                            <Button
                                                                variant='contained'
                                                                onClick={() => {
                                                                    updateOrderStatus(order.delivery_status + 1)
                                                                }}
                                                                color="primary">
                                                                Click to update this order as Shipped
                                                            </Button>
                                                            : order.delivery_status === 6 ?
                                                                <Button
                                                                    variant='contained'
                                                                    onClick={() => {
                                                                        updateOrderStatus(order.delivery_status + 1)
                                                                    }}
                                                                    color="primary">
                                                                    Click to confirm Return of this order
                                                                </Button>
                                                                : ''}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                        : ''}
                    <CardActions className='footer-wrapper'>
                        <div className="action-wrapper">
                            <Button variant="contained" color="error" onClick={() => discardUpdateOrder(false)}>
                                Go Back
                            </Button>
                            {/* <Button variant="contained" type="submit" color="secondary">
                                {selectedProduct ? 'Update Product' : 'Save Product'}
                            </Button> */}
                        </div>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default OrderDetails