import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const TutorialModal = ({open,handleClose}) => {

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Tutorial
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div style={{ width: '100%',  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <video
                        width="100%"
                        height="100%"
                        controls
                        src="https://webbie.s3.af-south-1.amazonaws.com/Sample_files_webbieshop/VID-20240703-WA0000Tutorialvideo.mp4"
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default TutorialModal;
