import { configureStore } from "@reduxjs/toolkit";

import reducer from "reducers";
export default configureStore({
    reducer: reducer,
});

// setting data in store
// store.dispatch({
//     type: 'ADD_TODO',
//     text: 'Use Redux'
// })
