import { useSnackbar } from 'notistack';

let useSnackbarRef;
export const SnackbarConfigurator = () => {
    useSnackbarRef = useSnackbar();
    return null;
};

export const notify = (message, variant) => {
    useSnackbarRef.enqueueSnackbar(message, { variant });
};
