import { useState } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions,
    FormLabel,
    FormControl,
    TextField
} from '@mui/material';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';

const Delivery = ({ address, handleNext, handleBack }) => {
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { register, formState: { errors }, handleSubmit } = useForm();

    // console.log('Address ', address)

    const updateDelivery = async (formData) => {
        setLoader(true)
        formData = {
            ...formData,
            latitude: 44.240309,
            longitude: -91.493619
        }
        let res = await axiosInstance.post(APIRouteConstants.STORE.GET_DELIVERY_LOCATION, formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        console.log('Data ', data, response)

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('Address updated successfully', { variant: 'success' });
            handleNext();
        }
        setLoader(false);
    }

    return (
        <div className="setup-wrapper website-analytics-wrapper">
            <Loader open={loader} />
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        <form onSubmit={handleSubmit(updateDelivery)}>
                            <CardContent>
                                <div className='header-wrapper'>
                                    <span className='header-text'>Pick-up address</span>
                                </div>
                                <Divider />
                                <div className='content-wrapper content-flex'>
                                    <span className='content-text'>
                                        Our local delivery options give you access to all our delivery partners, specific to your country. Our delivery partners will ensure that your goods get to your customer's door as quickly and easily as possible.
                                    </span>
                                </div>
                                <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                    {/* <Add /> */}
                                    <Grid item sm={12}>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.3325395304414!2d-122.01116148467422!3d37.33463524513264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb59127ce078f%3A0x18e1c3ce7becf1b!2sApple%20Park!5e0!3m2!1sen!2sin!4v1637309850935!5m2!1sen!2sin"
                                            width="100%"
                                            height="400"
                                            // style="border:0;"
                                            allowfullscreen=""
                                            loading="lazy"
                                        ></iframe>
                                    </Grid>
                                    <Grid item md={12} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Suburb
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="Name"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    {...register("suburb", { required: true })}
                                                    defaultValue={address?.suburb}
                                                    error={errors.suburb?.type}
                                                    helperText={errors.suburb?.type === "required" && (
                                                        'Suburb is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Name
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="Name"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    {...register("address_nick_name", { required: true })}
                                                    defaultValue={address?.address_nick_name}
                                                    error={errors.address_nick_name?.type}
                                                    helperText={errors.address_nick_name?.type === "required" && (
                                                        'Name is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Warehouse Number
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="Name"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    {...register("warehouse_number", { required: true })}
                                                    defaultValue={address?.warehouse_number}
                                                    error={errors.warehouse_number?.type}
                                                    helperText={errors.warehouse_number?.type === "required" && (
                                                        'Warehouse Number is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Address Line 1
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="Building Name, Plot Number"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={address?.address_line1}
                                                    {...register("address_line1", { required: true })}
                                                    error={errors.address_line1?.type}
                                                    helperText={errors.address_line1?.type === "required" && (
                                                        'Address line is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Address Line 2
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="Street, Locality"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={address?.address_line2}
                                                    {...register("address_line2", { required: true })}
                                                    error={errors.address_line2?.type}
                                                    helperText={errors.address_line2?.type === "required" && (
                                                        'Address line is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    City
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="City"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={address?.city}
                                                    {...register("city", { required: true })}
                                                    error={errors.city?.type}
                                                    helperText={errors.city?.type === "required" && (
                                                        'City is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Province / State
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="State"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={address?.state}
                                                    {...register("state", { required: true })}
                                                    error={errors.state?.type}
                                                    helperText={errors.state?.type === "required" && (
                                                        'State is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Country
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    placeholder="Country"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={address?.country}
                                                    {...register("country", { required: true })}
                                                    error={errors.country?.type}
                                                    helperText={errors.country?.type === "required" && (
                                                        'Country is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Pincode
                                                    <span className='required'>*</span>
                                                </span><br />
                                            </FormLabel>
                                            <FormControl className='form-control'>
                                                <TextField
                                                    margin="dense"
                                                    id="name"
                                                    placeholder="Postal Code"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={address?.postal_code}
                                                    {...register("postal_code", { required: true })}
                                                    error={errors.postal_code?.type}
                                                    helperText={errors.postal_code?.type === "required" && (
                                                        'Postal Code is required'
                                                    )}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className='footer-wrapper'>
                                <div className="action-wrapper">
                                    {/* <Button 
                                variant='outlined'
                                onClick={handleBack} >
                                {'<'} Back
                            </Button> */}
                                    <Button type="submit" variant='contained'>
                                        Next Step {'>'}
                                    </Button>
                                    <Button
                                        onClick={() => window.location.href = dashboardRouteConstants.HOME}
                                        variant="default">
                                        Skip
                                    </Button>
                                </div>
                            </CardActions>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Delivery