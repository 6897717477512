import { useState, useEffect } from 'react';

import {
    Card,
    CardContent,
    Divider,
    Grid,
    FormLabel,
    FormControl,
    Button,
    CardActions
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PrivacyPolicy = ({ breadcrumbs }) => {
    const [termsAndCondition, setTermsAndCondition] = useState('');
    const [loader, setLoader] = useState(false);
    const [refresh_page, setRefresh_Page] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const getTermsAndConditions = async () => {
        let res = await axiosInstance.get(APIRouteConstants.WEBSITE.PRIVACY_POLICY)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            setTermsAndCondition(data['privacy_policy'])
        }
    }

    const updateTermsAndConditions = async () => {
        if (!termsAndCondition) return;
        setLoader(true)
        setRefresh_Page(true)
        const res = await axiosInstance.post(APIRouteConstants.WEBSITE.PRIVACY_POLICY, {
            privacy_policy: termsAndCondition
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res


        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }
        if (!_.isEmpty(data)) {
            enqueueSnackbar('Details updated successfully', { variant: 'success' });
        }
        setLoader(false)
        setRefresh_Page(false)
    }

    useEffect(() => {
        getTermsAndConditions();
    }, [refresh_page]);

    const handleUpdate = () => {
        updateTermsAndConditions()
    }

    return (
        <div className="website-analytics-wrapper store-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Returns & Refunds"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent className='content-bottom-wrapper'>
                            <div className='header-wrapper'>
                                <span className='header-text'>Returns & Refunds</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                Please specify the return and refund policy are in-line with your business practices below. We strongly urge you to include this so as to protect you and your customers. You can also include your returns policy and any other legal terms you would like to govern how customers use your website.   </span>
                            </div>
                            <Grid container className='form-grid-container dashboard-form-container' spacing={4} wrap='wrap'>
                                {/* <Add /> */}
                                <Grid item md={12} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                            Returns & Refunds
                                            </span><br />
                                            <span className='label-sec-text' style={{fontWeight:"600", color:"#688ad5"}}>
                                            We have pre-populated the Returns & Refunds policy with a standard template. Please customize it to fit your specific business requirements.  </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <ReactQuill
                                                className='editor'
                                                theme="snow"
                                                style={{ marginBottom: '1.2rem' }}
                                                value={termsAndCondition}
                                                onChange={(e) => {
                                                    setTermsAndCondition(e);
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                {/* <Button variant="contained" color="error" >
                                    Discard
                                </Button> */}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={!termsAndCondition}
                                    onClick={handleUpdate}
                                >
                                    Update
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default PrivacyPolicy