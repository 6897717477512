import React, { useEffect, useState } from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "constants/appConstants";
import _ from "lodash";
import { DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Loader from "components/Loader";
function Privacypolicyv1() {
  const [privacyData, setPrivacyData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const getPrivacyPolicy = async () => {
    let res = await axios.get(PRIVACY_POLICY);
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }

    if (data && data.data && data.data.other_content_blocks) {
      setLoading(false);
      setPrivacyData(data.data.other_content_blocks[0]);
    }
  };
  console.log("privacy", privacyData);

  return (
    <WithFooter>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <div className="pricing">
          <div className="terms">
            <div className="terms__container">
              <DialogTitle sx={{ m: 0, pl: 2, fontSize: "1.4rem" }}>
                Webbie Shop {_.get(privacyData, "heading")}
              </DialogTitle>
              <div className="terms__container__content">
                <DialogTitle sx={{ m: 0, pl: 2, fontSize: "1.2rem" }}>
                  {_.get(privacyData, "subheading")}
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText
                    className="policy-wrapper"
                    id="alert-dialog-slide-description"
                  >
                    {/* <span style={{ fontWeight: "600", color: "black" }}>
                    {_.get(privacyData, "subheading")}
                  </span> */}
                    <p style={{ whiteSpace: "pre-line", fontSize: "15px" }}>
                      {_.get(privacyData, "text_content")}
                    </p>
                  </DialogContentText>
                </DialogContent>
                {/* <div className="text">{privacyData?.text_content}</div> */}
              </div>
              {/* <div className="terms__container__content">
              <div className="heading">
                Websites covered by this Privacy Policy
              </div>
              <div className="text">
                This Privacy Policy applies to all our websites and domains
                including all regional or country-specific sites (collectively
                the “Websites”). The Websites may provide links to third-party
                websites for your convenience and information. If you access
                those links, you will leave the Websites. We do not control
                those sites or their privacy practices, which may differ from
                our own privacy practices. This Privacy Policy does not cover
                any personal data that you choose to give to unrelated third
                parties. We do not monitor or control the information collected
                by such sites or the privacy practices of any third parties, and
                we are not responsible for their practices or the content of
                their sites.
              </div>
            </div> */}
              {/* <div className="terms__container__content">
              <div className="heading">
                Types of information we collect and use
              </div>
              <div className="text">
                In this policy, “personal information” or “personal data” means
                information about an identifiable individual that is subject to
                protection under the law in the jurisdiction in which you
                reside. In some jurisdictions, “personal information” or
                “personal data” will not include business contact information.
                The Websites collect information through a variety of different
                ways and for different purposes as follows. If you choose to
                register with any of the Websites to receive updates from us, to
                manage your account and/or use our self-service portal, then you
                will be requested to provide contact information (name, address,
                telephone number, and email address). We use this information
                pursuant to our legitimate business interests to provide our
                services and/or to contact you about the services on our site in
                which you have expressed interest. The Websites automatically
                collect technical information about your visit (such as browser
                type, Internet service provider, platform type, internet
                protocol (IP) addresses, referring/exit pages, operating system,
                date/time stamp). We aggregate this information for reporting
                about the Websites to analyse trends, diagnose problems with our
                server and administer the Websites, to track user movement and
                use, and to gather broad demographic information. See ‘Cookies
                and other digital markers’ below for more information. You also
                have the option to provide demographic information (such as type
                of business, size of business, locations, etc.). We use this
                demographic information to understand your needs and interests
                and to provide you a more personalized experience on our site.
                The information is used by the group to process your orders,
                enable participation in promotions (subject to your marketing
                preferences), and so that we can provide our services to you. If
                we speak to you over the phone, you may provide us with personal
                data during the call. Such calls may be recorded for training
                and quality purposes. You will be informed at the outset of a
                call if it is to be recorded, giving you an opportunity to end
                the call if you are not comfortable with recording.
              </div>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </WithFooter>
  );
}

export default Privacypolicyv1;
