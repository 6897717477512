import React, { useState } from "react";

import {
    InputBase
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';

import { styled, alpha } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border: '1px solid black',
    borderRadius: 'inherit',
    '& .MuiInputBase-input': {
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
}));

function SearchInput({
  onChange,
  value,
  onBlur,
  onEnter
}) {
  return (
    <Search className="search-bar">
        <SearchIconWrapper className="search-icon">
            <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onEnter()
              }
            }}
            className="search-input"
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
        />
    </Search>
  );
}
export default SearchInput;
