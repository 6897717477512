import React from "react";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import Backicon from "../../assets/images/images/backicon.svg";
import Googleicon from "../../assets/images/images/google-icon.svg";
import Hideicon from "../../assets/images/images/Hide-Icon.svg";
import { Link } from "react-router-dom";
function Loginv1() {
  return (
    <section class="signin">
      <article class="signin__leftimg">
        <img
          src={require("../../assets/images/images/login-img.png")}
          alt=""
          class="signin__leftimg__img"
        />
        {/* <!-- <img src="images/left-coverimg.png" alt="" class="signin__leftimg__img-cover"> --> */}
        {/* <!-- <div class="signin__leftimg__textcontainer">
                  <div class="signin__leftimg__textcontainer__heading">
                      <div>Partnership for</div>
                      <div>Business Growth</div>
                  </div>
                  <div class="signin__leftimg__textcontainer__content">
                      Lorem ipsum dolor sit amet consectetur 
                      adipisicing elit. Aut qui molestias architecto cupiditate .
                  </div>
              </div> --> */}
      </article>
      <article class="signin__right">
        <div class="signin__right__top">
          <Link to={"/"} class="signin__right__top__btn">
            <img src={Backicon} alt="backicon" />
            <span>RETURN HOME</span>
          </Link>
          <div class="signin__right__top__rightcontent">
            Not a member yet? <Link to={"/auth/signup"}>JOIN NOW</Link>
          </div>
        </div>
        <form class="signin__right__form">
          <div class="signin__right__form__topcontent">
            <div>Sing in to WebbieShop</div>
            <div>Enter your Username and Password to Login</div>
            <div></div>
          </div>
          <div class="signin__right__form__logingooglebtn">
            <button class="signin__right__form__logingooglebtn__btn">
              <img src={Googleicon} alt="" />
              <div>Sign in with Google</div>
              <div>lorem</div>
            </button>
            <div class="signin__right__form__logingooglebtn__content">
              OR SIGNIN WITH EMAIL
            </div>
          </div>
          <div class="signin__right__form__input-fields">
            <div class="signin__right__form__input-fields__input-item">
              <label for="email">USERNAME OR EMAIL</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="debra.holt@example.com"
              />
            </div>
            <div class="signin__right__form__input-fields__input-item">
              <div class="signin__right__form__input-fields__input-item__labeldiv">
                <div>PASSWORD</div>
                <div>Forgot your password?</div>
              </div>
              <div class="signin__right__form__input-fields__input-item-password">
                <input
                  type="password"
                  name="password"
                  id="password"
                  class="signin__right__form__input-fields__input-item-password-input"
                />
                <div class="signin__right__form__input-fields__input-item-password-icon">
                  <img src={Hideicon} alt="hideicon" />
                </div>
              </div>
            </div>
          </div>
          <div class="signin__right__form__signinbtn">
            <button>LOGIN</button>
          </div>
        </form>
      </article>
    </section>
  );
}

export default Loginv1;
