import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';

export default function FormDialog({
    open,
    setOpen,
    title,
    viewCancel,
    viewSave,
    handleSave,
    className,
    ...props
}) {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    return (
        <Dialog open={open} className={`${className} form-dialog-wrapper`}>
            <DialogTitle><span className='title-text'>{title}</span></DialogTitle>
            <Divider />
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions className='dialog-action'>
            {viewCancel ? 
              <Button variant='contained' color="error" size="small" onClick={handleClose}>Cancel</Button> : '' }
            {viewSave ?
              <Button 
                variant='contained' 
                color="secondary" 
                size="small" 
                onClick={handleSave}>Save</Button>
              : '' }
            </DialogActions>
        </Dialog>
    );
}
