import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Toolbar,
    Collapse
} from '@mui/material';
import { useNavigate } from "react-router-dom";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import _ from 'lodash';
import { dashboardRouteConstants } from 'constants/routeConstants';
import { useModal } from 'utils/context';

const CollapseList = ({ item, isDisable }) => {
    const { pathname } = useLocation();

    const getSelected = () => {
        return _.find(item.child, { path: pathname })
    }

    const [open, setOpen] = useState(getSelected());

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton disabled={isDisable} onClick={handleClick}>
                <ListItemIcon>
                    {/* <InboxIcon /> */}
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title}   primaryTypographyProps={{
                      sx: { fontWeight: "700" },
                    }}/>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.child.map((i, index) => {
                      return(
                        <ListItemButton sx={{ pl: 4 }} disabled={isDisable} href={i.path || ''}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary={i.title} />
                        </ListItemButton>
                    )})}
                </List>
            </Collapse>
        </>
    )
}

function SideNavigationLeft(props) {
    const { window, drawerWidth, appTitle, navItems, isDisable } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate()

    const { handleOpen, handleClose } = useModal();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
      <div>
        <Toolbar>
          <div className="logo-wrapper">
            <div
              onClick={() => navigate(dashboardRouteConstants.HOME)}
              style={{ cursor: "pointer" }}
              className="img-wrapper"
            >
              <img
                src={require("../../../assets/images/logo-title-white.png")}
                alt="logo"
              />
            </div>
            {/* <Typography
      <div>
        <Toolbar>
          <div className="logo-wrapper">
            <div
              onClick={() => navigate(dashboardRouteConstants.HOME)}
              style={{ cursor: "pointer" }}
              className="img-wrapper"
            >
              <img
                src={require("../../../assets/images/logo-title-white.png")}
                alt="logo"
              />
            </div>
            {/* <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    disabled={isDisable}
                    href="/"
                    sx={{
                        mr: 2,
                        fontFamily: "monospace",
                        fontWeight: 700,
                        letterSpacing: ".3rem",
                        color: "white",
                        textDecoration: "none",
                    }}
                >
                    {appTitle}
                </Typography> */}
          </div>
        </Toolbar>
        {/* <Divider /> */}
        <List className="list-wrapper">
          {navItems.map((item, index) =>
            !item.child ? (
              <ListItem key={index} disablePadding>
                {item.path === "info" ? (
                  <ListItemButton
                    disabled={item.title == "Logout" ? false : isDisable}
                    onClick={handleOpen}
                  >
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    disabled={item.title == "Logout" ? false : isDisable}
                    href={item.path || ""}
                  >
                    <ListItemIcon>
                      {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                )}
              </ListItem>
            ) : (
              <CollapseList isDisable={isDisable} key={index} item={item} />
            )
          )}
        </List>
      </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                className="sidenav-mb-drawer"
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                className="sidenav-drawer"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

SideNavigationLeft.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SideNavigationLeft;
