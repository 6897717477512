import React, { useState } from "react";

import {
  Card,
  Grid,
  FormControl,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  FormLabel,
  Divider,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";
import { enqueueSnackbar } from "notistack";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import OTP from "components/Inputs/OTP";
import Changepassword from "containers/Changepassword";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const doEnterEmail = async (formData) => {
    setLoader(true);
    formData = {
      ...formData,
      email: formData.email,
      user_type: 3,
    };
    let res = await axiosInstance.post(
      APIRouteConstants.AUTH.FORGOTPASSEMAIL,
      formData
    );

    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;
    // navigate(`/auth/otp-validate`)
    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        setLoader(false);
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    } else if (response && response.status === 404 && response.data) {
      console.log("res", response?.data)
      setLoader(false);
      enqueueSnackbar(response.data.message, {
        variant: "error",
      });
      return
    }
    setStep(1);
    setLoader(false);
    setEmail(formData?.email);
  };

  const verifyOTP = async () => {
    const payload = {
      email: email,
      user_type: 3,
      otp: otp,
    };
    setLoader(true);
    let res = await axiosInstance.post(
      APIRouteConstants.AUTH.OTPVALIDATECHANGEPASSWORD,
      payload
    );

    if (res === false) {
      setLoader(false);
      return
    }

    let { data, response } = res;
    setLoader(false);
    setUid(res?.data?.uid);
    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        setLoader(false);
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }
    if (data && data.success) {
      enqueueSnackbar("OTP verified successfully", { variant: "success" });
      setStep(2);
    }
  };

  return (
    <div>
      {step == 0 && (
        <div className="login-wrapper">
          <Loader open={loader} />
          <Card className="card-wrapper">
            <span className="form-label">Forgot your password?</span>
            <h5 className="text-wrapper" style={{ textAlign: "left" }}>
              Please enter the email associated with your account and we'll send
              you the OTP to reset your password
            </h5>
            <form onSubmit={handleSubmit(doEnterEmail)}>
              <Grid container className="form-container">
                <Grid item sm={12} className="form-wrapper">
                  <FormControl className="form-control" fullWidth>
                    <TextField
                      margin="dense"
                      id="email"
                      placeholder="Enter Email"
                      type="email"
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Enter Email"
                      {...register("email", {
                        required: true,
                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                      })}
                      error={errors.email?.type}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={12} className="form-wrapper">
                  <div className="button-wrapper">
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "#bb3377",
                        "&:hover": {
                          background: "#bb3377", // The color you want on hover
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className="link-wrapper">
                    <a href="/auth/login">
                      <span>Back to Login</span>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Card>
          {/* <Card className="sec-card-wrapper">
                        <div className="text-wrapper">
                            <span>By continuing, you agree to the Terms and Conditions & Privacy Policy.</span>
                        </div>
                    </Card> */}
        </div>
      )}
      {step == 1 && (
        <div className="login-wrapper">
          <Loader open={loader} />
          <Card
            style={{ padding: "14px" }}
            className="list-wrapper dashboard-card-wrapper"
          >
            <h4 className="title-text" style={{ marginBottom: "5px" }}>
              Enter the OTP sent to your <br />
              registered email
            </h4>
            <Divider />
            <DialogContent >
              <div className="form-wrapper">
                <FormLabel className="form-label">
                  {/* <span className='label-text'>
                                    OTP
                                </span><br /> */}
                  <h4 className="label-sec-text"></h4>
                </FormLabel>
                <FormControl className="form-control">
                  <OTP
                    style={{ marginTop: "1rem" }}
                    separator={<span>-</span>}
                    value={otp}
                    onChange={setOTP}
                    length={5}
                  />
                </FormControl>
              </div>
            </DialogContent>
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                marginLeft: "18%",
              }}
              className="action-wrapper"
            >
              <Button
                sx={{ width: "fit-content", mr: 1, background: "#cd1479" }}
                variant="contained"
                disabled={otp?.length < 5}
                onClick={() => verifyOTP()}
              >
                Verify
              </Button>
              <Button
                sx={{ width: "fit-content" }}
                variant="outlined"
                onClick={() => navigate(`/auth/login`)}
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </Card>
        </div>
      )}
      {step == 2 && <Changepassword uid={uid} />}
    </div>
  );
};

export default ForgotPassword;
