import axios from "axios";
import { getAccessToken } from "utils";
import { authRouteConstants } from "constants/routeConstants";
import { API_URL } from 'constants/appConstants'
import { notify } from "../Notify";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    Authorization: (function () {
      const token = getAccessToken();
      if (token) return `Bearer ${token}`
      return '';
    })()
  }
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error response interceptors", error.response, error);
    // if (error && error.response && error.response.status === 400) {
    //   window.location.href = authRouteConstants.LOGIN;
    // }
    // return Promise.reject(error);

    if (error && error.response) {
      if (error.response.status === 401) {
        notify("authentication error.", "error");
        window.location.href = authRouteConstants.LOGIN;
        return false;
      } 
      // else if (error.response.status === 404) {
      //   notify("The requested resource was not found.", "error");
      //   return false;
      // } 
      else if (error.response.status === 504) {
        notify("The server is currently unavailable. Please try again later.", "error");
        return false;
      }
      else if (error.response.status === 500) {
        notify("Something went wrong.", "error");
        return false;
      }
    } else if (error && error.message === 'Network Error') {
      notify("A network error occurred. Please check your internet connection.", "error");
      return false;
    }

    return error
  }
);

export default instance;