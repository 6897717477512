import { useState, useEffect } from 'react';
import _ from 'lodash';

import {
    Card,
    CardContent,
    Divider,
    Grid,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment
} from '@mui/material';
import { useSnackbar } from 'notistack';

import CustomBreadcrumbs from "components/Breadcrumbs";
import FormDialog from 'components/Dialog/FormDialog';
import TableWithAction from 'components/Tables/TableWithAction';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';

const Domains = ({ breadcrumbs }) => {
    const [isEditDomain, setEditDomain] = useState(false);
    const [domainDetail, setDomainDetail] = useState('');
    const [domain, setDomain] = useState('');
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const loggedInUser = useSelector(state => state.userDetails);
    const { user } = loggedInUser;

    const getWebDomain = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_DOMAIN)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data && data['host_name']) {
            setDomain(data['host_name'])
            setDomainDetail([{
                domain: data['host_name'] + '.webbieshop.com',
                status: 'Verified'
            }]);
        } else {
            setEditDomain(true)
        }

        setLoader(false)
    };

    useEffect(() => {
        getWebDomain()
    }, [])

    const updateDomain = async (value) => {
        setLoader(true);
        let res = await axiosInstance.put(APIRouteConstants.WEBSITE.WEB_DOMAIN, {
            host_name: value,
            status: true
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('Domain updated successfully', { variant: 'success' });
            refreshDomain();
        }
        setLoader(false);
    }

    const handleUpdateDomain = () => {
        if (!domain) {
            enqueueSnackbar('Domain is mandatory', { variant: 'error' });
            return;
        }
        updateDomain(domain);
    }

    const refreshDomain = () => {
        setEditDomain(false);
        getWebDomain();
    }

    return (
        <div className="website-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Domain"} />
            </div>
            <Grid container className='component-grid' spacing={4} wrap='wrap'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Manage Domain</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    This section allows you to manage domains that are already connected to your Webbie Shop store. You can also buy a domain through the platform, we will then handle connecting the domain to your store automatically. If you already have a domain that you have bought elsewhere, you can choose to connect the domain to your Webbie Shop store. There are simple instructions to do that under the Connect tab.                                </span>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Default domain</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    Your default domain is the free sub-domain we provide you with as part of the service as a subscriber.
                                </span>
                            </div>
                            <Grid container className='dashboard-table-container' spacing={4} wrap='wrap'>
                                <Grid item md={12} sm={12}>
                                    <TableWithAction
                                        rows={domainDetail}
                                        headers={[{
                                            label: 'Domain',
                                            name: 'domain',
                                            width: '70%',
                                        }, {
                                            label: 'Status',
                                            name: 'status',
                                            type: 'status',
                                            labelName: {
                                                true: 'Verified',
                                                false: 'Pending'
                                            }
                                        }]}
                                        handleEdit={() => setEditDomain(!isEditDomain)}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <FormDialog
                setOpen={setEditDomain}
                viewSave={user ? !user.isPublished : true}
                viewCancel={domainDetail.length}
                open={isEditDomain}
                title={"Edit default domain"}
                handleSave={handleUpdateDomain}
            >
                <div className='form-wrapper'>
                    <FormLabel className='form-label'>
                        <span className='label-text'>
                            Store website address
                        </span><br />
                        <span className='label-sec-text'>
                            You can change your free domain name later.
                        </span>
                    </FormLabel>
                    <FormControl className='form-control'>
                        <TextField
                            margin="dense"
                            id="name"
                            placeholder="Enter Domain"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={domain}
                            disabled={user?.isPublished}
                            onChange={e => setDomain(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">.webbieshop.com</InputAdornment>,
                            }}
                            InputLabelProps={{ shrink: false }}
                        />
                    </FormControl>
                </div>
            </FormDialog>
        </div>
    )
}

export default Domains