import { useCallback, useEffect, useState } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions,
    Switch,
    FormLabel,
    FormControl,
    TextField,
    Paper,
    InputBase,
    IconButton,
    Typography
} from '@mui/material';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import {
    setKey,
    fromAddress,
    geocode,
    RequestType
} from "react-geocode";

import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
import CustomBreadcrumbs from "components/Breadcrumbs";
import Loader from 'components/Loader';
import AddCircle from '@mui/icons-material/AddCircleOutline';
import { useSelector } from 'react-redux';
import BasicTable from 'components/Tables/BasicTable';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { GOOGLE_KEY } from 'constants/appConstants';

const showName = (args) => {
    return _.compact(args).join(' ')
}

const showAddress = (
    addressLine1,
    addressLine2,
    city,
    country,
    postalCode,
    suburb,
    warehouseNumber,
    warehouseAddressName
) => {
    return (
        <div className="address-wrapper">
            <span>{warehouseAddressName}</span><br />
            <Divider className='content-divider' />
            <span>{warehouseNumber}, {addressLine1}</span><br />
            <span>{addressLine2}</span><br />
            <span>{suburb}</span><br />
            <span>{city}, {country} - {postalCode}</span>
        </div>
    )
}

const contactInfo = (
    country_code,
    mobile_number,
    email_address
) => {
    return (
        <div className="address-wrapper">
            <span>+{country_code}-{mobile_number}</span><br />
            <Divider className='content-divider' />
            <span>{email_address}</span>
        </div>
    )
}

const Delivery = ({ breadcrumbs }) => {
    const [isSetupComplete, setWarehouseSetup] = useState(true);
    const [viewAddAddress, setAddAddress] = useState(false);
    const [allAddress, setAddresses] = useState([]);
    const [location, selectLocation] = useState();
    const [logistics, setLogisticList] = useState([]);
    const [loader, setLoader] = useState(false);

    const [shippingAmount, setshippingAmount] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const loggedInUser = useSelector(state => state.userDetails);
    const { user } = loggedInUser
    const navigate = useNavigate()

    const getDeliveryAddress = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_DELIVERY_LOCATION)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status == 404) {
            setWarehouseSetup(false)
            setAddAddress(false)
        }

        if (data && data.length > 0)
            setAddresses(data)
        else {
            setAddresses([])
            setAddAddress(true)
        }
        setLoader(false)
    };

    const updateDelivery = async (formData) => {
        setLoader(true)
        formData = {
            ...formData,
            latitude: location.lat,
            longitude: location.lng
        }
        let res = await axiosInstance.post(APIRouteConstants.STORE.UPDATE_DELIVERY_LOCATION, formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
            setLoader(false);
        }

        if (data) {
            enqueueSnackbar('Address updated successfully', { variant: 'success' });
            getDeliveryAddress()
            setAddAddress(false)
        }
    }

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_KEY,
        libraries: ['places']
    });
    const getShippingAmount = async () => {
        try {
            setLoader(true);
            let res = await axiosInstance.get(
                APIRouteConstants.SHIPRAZOR.GET_SHIPPING_AMOUNT
            );
            if (res === false) {
                setLoader(false);
                return
            }
            let { data, response } = res;
            if (data && res.status) {
                setshippingAmount(data?.amount);
            } else {
                enqueueSnackbar(response?.data?.error, { variant: "error" });
            }
            setLoader(false);
        } catch (err) {
            console.log("getShippingAmount", err);
        }
    };
    console.log("user", user)
    useEffect(() => {
        if (!_.isEmpty(user)) {
            if (user?.is_shiprazor_selected ? true : (user.own_shipping_selected && user?.shipping_amount !== null) ? true : false
            ) {
                getDeliveryAddress()
                setCurrentLocation()
            } else {
                setWarehouseSetup(false)
                setAddAddress(false)
                setWarehouseSetup(false)
            }
        }
        if (user?.own_shipping_selected) {
            getShippingAmount()
        }
    }, [user])

    const retrieveLocation = (location) => {
        const locationObj = location[location.length - 1].address_components.reduce(
            (acc, component) => {
                if (component.types.includes['street_number']
                    || component.types.includes("premise")
                    || component.types.includes("neighborhood")
                    || component.types.includes("sublocality_level_3")
                    || component.types.includes("route")) {
                    if (acc.address)
                        acc.address = acc.address + ', ' + component.long_name;
                    else
                        acc.address = component.long_name
                } else if (component.types.includes("administrative_area_level_3")) {
                    acc.city = component.long_name;
                }
                else if (component.types.includes("locality")) {
                    acc.suburb = component.long_name;
                } else if (component.types.includes("sublocality_level_1")) {
                    if (!acc.suburb)
                        acc.suburb = component.long_name;
                } else if (component.types.includes("sublocality_level_2")) {
                    if (!acc.suburb)
                        acc.suburb = component.long_name;
                } else if (component.types.includes("administrative_area_level_1"))
                    acc.state = component.long_name;
                else if (component.types.includes("country"))
                    acc.country = component.long_name;
                else if (component.types.includes("postal_code"))
                    acc.pincode = component.long_name;
                return acc;
            },
            {}
        );
        return locationObj
    }

    const fetchLocation = async (lat, lon) => {
        setKey(GOOGLE_KEY);
        geocode(RequestType.LATLNG, [lat, lon].toString(), {
            location_type: "ROOFTOP",
            enable_address_descriptor: true,
        }).then(({ results }) => {
            const { address, state, country, pincode, city, suburb } = retrieveLocation(results);
            setValue('address_line1', address);
            setValue('suburb', suburb);
            setValue('city', city);
            setValue('state', state);
            setValue('country', country);
            setValue('postal_code', pincode);
        }).catch((area) => {
            console.error("geocode error",area)
            // enqueueSnackbar('Something went wrong! Please try again', { variant: 'error' });
        })
        setLoader(false)
    }

    const [mapInstance, setMapInstance] = useState(null);
    const [zoom, setZoom] = useState(12);
    const onMapLoad = useCallback((map) => {
        setMapInstance(map);
    }, []);

    useEffect(() => {
        if (mapInstance && location) {
            mapInstance.panTo({ lat: location.lat, lng: location.lng });
            if (location) setZoom(16);
        }
    }, [mapInstance, location]);

    const onMapClick = (e) => {
        setLoader(true)
        selectLocation({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        });
        fetchLocation(e.latLng.lat(), e.latLng.lng())
    };

    const [autocomplete, setAutocomplete] = useState(null);
    const [locationError, setLocationError] = useState(null);

    const handlePlaceSelect = () => {
        setLocationError("")
        if (autocomplete?.getPlace()?.formatted_address === undefined) {
            console.log("first")
            // setLocationError("Location not found")
            return
        };
        setLoader(true);
        const addressObject = autocomplete.getPlace();
        const address = addressObject.formatted_address;
        const lat = addressObject.geometry.location.lat();
        const lng = addressObject.geometry.location.lng();

        selectLocation({ lat, lng });

        setKey(GOOGLE_KEY);

        geocode(RequestType.ADDRESS, address)
            .then(({ results }) => {
                // Handle response
                console.log('Results ', results)
                const { lat, lng } = results[0].geometry.location;
                const { address, state, country, pincode, city, suburb } = retrieveLocation(results);
                setValue('address_line1', address);
                setValue('suburb', suburb);
                setValue('city', city);
                setValue('state', state);
                setValue('country', country);
                setValue('postal_code', pincode);

                selectLocation({
                    lat: lat,
                    lng: lng
                })
            })
            .catch(error => {
                // Handle error
                console.log('Error ', error)
                enqueueSnackbar('Location not found', { variant: 'error' });
            });

        setLoader(false);
    };

    const setCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            selectLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
            fetchLocation(position.coords.latitude, position.coords.longitude)
        });
    }

    const handleFormRefresh = () => {
        selectLocation()
        setAddAddress(false)
        setCurrentLocation()
    }

    const handleInputBaseKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <div className="store-analytics-wrapper website-analytics-wrapper">
            <Loader open={!isLoaded || loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Delivery options"} />
            </div>
            {!viewAddAddress ?
                <Grid container className='component-grid'>
                    <Grid item xs={12}>
                        <Card className='list-wrapper dashboard-card-wrapper'>
                            <CardContent>
                                <div className='header-wrapper header-flex'>
                                    <span className='header-text'>Local delivery</span>
                                    <Button
                                        // onClick={() => setNewProduct(true)}
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        onClick={() => setAddAddress(true)}
                                        disabled={_.isEmpty(user) ? true : !(user.is_delivery_option_selected)}
                                        startIcon={<AddCircle />}>
                                        Add New Address
                                    </Button>
                                </div>
                                <Divider />
                                <div className='content-wrapper'>
                                    <span className='content-text'>
                                        Our local delivery options give you access to all our delivery partners, specific to your country. Our delivery partners will ensure that your goods get to your customer's door as quickly and easily as possible.
                                    </span><br /><br />
                                    {!isSetupComplete ?
                                        <span className='content-text'>
                                            Please setup your shiprazor account with the registered email and phone number.
                                            {/* or if you have selected own shipping please add shipping cost. */}
                                        </span>
                                        : ''}
                                </div>
                                {isSetupComplete ?
                                    <div className='content-wrapper content-flex'>
                                        <Grid container className='filter-wrapper'>
                                            <BasicTable
                                                rows={allAddress}
                                                headers={[{
                                                    label: 'Address Name',
                                                    name: 'warehouse_address_name',
                                                    width: '15%'
                                                }, {
                                                    label: 'Address',
                                                    type: 'custom',
                                                    arguments: ['address_line1', 'address_line2', 'city', 'country', 'postal_code', 'suburb', 'warehouse_number', 'warehouse_address_name'],
                                                    width: '50%',
                                                    component: (
                                                        addressLine1,
                                                        addressLine2,
                                                        city,
                                                        country,
                                                        postalCode,
                                                        suburb,
                                                        warehouseNumber,
                                                        warehouseAddressName
                                                    ) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                {showAddress(
                                                                    addressLine1,
                                                                    addressLine2,
                                                                    city,
                                                                    country,
                                                                    postalCode,
                                                                    suburb,
                                                                    warehouseNumber,
                                                                    warehouseAddressName
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'Contact No',
                                                    type: 'custom',
                                                    arguments: ['country_code', 'mobile_number', 'email_address'],
                                                    width: '20%',
                                                    component: (
                                                        country_code,
                                                        mobile_number,
                                                        email_address
                                                    ) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                {contactInfo(
                                                                    country_code,
                                                                    mobile_number,
                                                                    email_address
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                }
                                                    // , {
                                                    //     label: 'Primary Address',
                                                    //     type: 'custom',
                                                    //     arguments: ['isPrimaryAddress'],
                                                    //     component: (isPrimaryAddress) => {
                                                    //         return (
                                                    //             isPrimaryAddress ? 'True' : 'False'
                                                    //         )
                                                    //     },
                                                    //     width: '15%'
                                                    // }
                                                ]}
                                            />
                                            {logistics.length > 0 ? logistics.map((logistic) =>
                                                <Grid item sm={12} key={logistic.id} className='payment-card'>
                                                    <span className='detail-sec-text'>
                                                        {logistic.company_name}
                                                    </span>
                                                    <Switch
                                                        checked={logistic.status}
                                                        disabled
                                                    />
                                                </Grid>
                                            ) : ''}
                                        </Grid>
                                    </div>
                                    : ''}
                            </CardContent>
                            <CardActions className='footer-wrapper'>
                                <div className="action-wrapper">
                                    {!isSetupComplete ?
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            style={{ backgroundColor: 'white' }}
                                            onClick={() => {
                                                navigate(dashboardRouteConstants.HOME)
                                            }}
                                        >Go To Dashboard</Button> :
                                        !user?.isPublished ?
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                style={{ backgroundColor: 'white' }}
                                                onClick={() => {
                                                    navigate(dashboardRouteConstants.HOME)
                                                }}>
                                                Next {'>'}
                                            </Button>
                                            : ''}
                                </div>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                :
                <Grid container className='component-grid'>
                    <Grid item xs={12}>
                        <Card className='list-wrapper dashboard-card-wrapper'>
                            {!loader ?
                                <form onSubmit={handleSubmit(updateDelivery)}>
                                    <CardContent>
                                        <div className='header-wrapper'>
                                            <span className='header-text'>Pick-up address</span>
                                        </div>
                                        <Divider />
                                        <div className='content-wrapper content-flex'>
                                            <span className='content-text'>
                                                Our local delivery options give you access to all our delivery partners, specific to your country. Our delivery partners will ensure that your goods get to your customer's door as quickly and easily as possible.
                                            </span>
                                        </div>
                                        <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <div className='form-wrapper'>
                                                    <FormControl className='form-control' style={{ width: '100%' }}>
                                                        <Autocomplete
                                                            onLoad={autocomplete => setAutocomplete(autocomplete)}
                                                            onPlaceChanged={handlePlaceSelect}
                                                        >
                                                            <Paper
                                                                component="form"
                                                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                                            >
                                                                <InputBase
                                                                    sx={{ ml: 1, flex: 1 }}
                                                                    placeholder="Search your Area"
                                                                    inputProps={{ 'aria-label': 'search google maps' }}
                                                                    onKeyDown={handleInputBaseKeyDown}

                                                                />
                                                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </Paper>
                                                        </Autocomplete>

                                                    </FormControl>
                                                    <Typography fontSize={"14px"} color={"#dd4498"}>{locationError}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className='form-wrapper' style={{ marginBottom: '1rem' }}>
                                                    <FormLabel className='form-label'>
                                                        <span className='label-text'>
                                                            or Select your delivery location
                                                            <span className='required'>*</span>
                                                        </span><br />
                                                    </FormLabel>
                                                </div>
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    center={location}
                                                    onClick={onMapClick}
                                                    zoom={zoom}
                                                    onLoad={onMapLoad}
                                                >
                                                    <Marker
                                                        icon={{
                                                            url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Adjust the URL as needed
                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                        }}
                                                        position={location} />
                                                    <IconButton
                                                        type="button"
                                                        onClick={setCurrentLocation}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            right: '10px',
                                                            zIndex: '9999',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'white',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '5px',
                                                        }}
                                                        aria-label="search">
                                                        <GpsFixedIcon />
                                                    </IconButton>
                                                </GoogleMap>
                                            </Grid>
                                            {!loader && location ?
                                                <>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Warehouse Address Name
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    margin="dense"
                                                                    placeholder="Name"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("warehouse_address_name", { required: true })}
                                                                    error={errors.warehouse_address_name?.type}
                                                                    helperText={errors.warehouse_address_name?.type === "required" && (
                                                                        'Name is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    {/* <Grid item md={6} sm={12} xs={12}></Grid> */}
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Warehouse Number
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    margin="dense"
                                                                    placeholder="Warehouse Number"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    // defaultValue={address?.address_line1}
                                                                    {...register("warehouse_number", { required: true })}
                                                                    error={errors.warehouse_number?.type}
                                                                    helperText={errors.warehouse_number?.type === "required" && (
                                                                        'Warehouse No is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}></Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Address Line
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    margin="dense"
                                                                    placeholder="Street, Locality"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("address_line1", { required: true })}
                                                                    error={errors.address_line1?.type}
                                                                    helperText={errors.address_line1?.type === "required" && (
                                                                        'Address line is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Address Line 2
                                                                    {/* <span className='required'>*</span> */}
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    margin="dense"
                                                                    placeholder="Street, Locality"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("address_line2")}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Suburb
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    margin="dense"
                                                                    placeholder="Suburb"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("suburb", { required: true })}
                                                                    error={errors.suburb?.type}
                                                                    helperText={errors.suburb?.type === "required" && (
                                                                        'Suburb is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    City
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    margin="dense"
                                                                    placeholder="City"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("city", { required: true })}
                                                                    error={errors.city?.type}
                                                                    helperText={errors.city?.type === "required" && (
                                                                        'City is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Province / State
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    disabled={true}
                                                                    margin="dense"
                                                                    placeholder="State"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("state", { required: true })}
                                                                    error={errors.state?.type}
                                                                    helperText={errors.state?.type === "required" && (
                                                                        'State is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Country
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    disabled={true}
                                                                    margin="dense"
                                                                    placeholder="Country"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("country", { required: true })}
                                                                    error={errors.country?.type}
                                                                    helperText={errors.country?.type === "required" && (
                                                                        'Country is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} sm={12} xs={12}>
                                                        <div className='form-wrapper'>
                                                            <FormLabel className='form-label'>
                                                                <span className='label-text'>
                                                                    Pincode
                                                                    <span className='required'>*</span>
                                                                </span><br />
                                                            </FormLabel>
                                                            <FormControl className='form-control'>
                                                                <TextField
                                                                    // disabled={true}
                                                                    margin="dense"
                                                                    id="name"
                                                                    placeholder="Postal Code"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register("postal_code", { required: true })}
                                                                    error={errors.postal_code?.type}
                                                                    helperText={errors.postal_code?.type === "required" && (
                                                                        'Postal Code is required'
                                                                    )}
                                                                    InputLabelProps={{ shrink: false }} onKeyDown={handleInputBaseKeyDown}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                </>
                                                : ''}
                                        </Grid>
                                    </CardContent>
                                    <CardActions className='footer-wrapper'>
                                        <div className="action-wrapper">
                                            {location ?
                                                <Button type="submit" variant="contained" color="secondary">
                                                    Save Changes
                                                </Button>
                                                : ''}
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: 'white' }}
                                                color="error"
                                                onClick={handleFormRefresh}>
                                                Cancel
                                            </Button>
                                            {/* {!user?.isPublished ?
                                <Button 
                                    variant="outlined" 
                                    color="secondary"
                                    style={{ backgroundColor: 'white' }} 
                                    onClick={() => {
                                        navigate(dashboardRouteConstants.HOME)
                                    }}>
                                    Next {'>'}
                                </Button>
                            : '' } */}
                                        </div>
                                    </CardActions>
                                </form> : ''}
                        </Card>
                    </Grid>
                </Grid>}
        </div>
    )
}

export default Delivery