import React, { useState } from "react";

import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Container,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    Switch,
    CircularProgress
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { dashboardRouteConstants } from "constants/routeConstants";
import { useModal } from "utils/context";

// const CustomButton = styled(Button)({
//     '&:hover .MuiSvgIcon-root': {
//       color: 'inherit', // Keep the original color when hovered
//     },
//   });

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress style={{ color: 'white' }} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '0px !important'
                }}
            >
                <Typography variant="caption" component="div" color="white">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

function TransparentAppBar({
    pages = [],
    rightAlignedPages = [],
    actionItems = [],
    infoStatus = false,
    anchor = "right",
    appTitle,
    showTitle = true,
    progress,
    published = false,
    handlePublish,
    websiteUrl
}) {
    const navigate = useNavigate();

    const { handleOpen, handleClose } = useModal();
    const list = (anchor) => {
        
        
        return (
          <Box
            sx={{
              width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <List className="action-item-nav-btn action-wrapper">
              {actionItems.length > 0
                ? actionItems.map((page, key) => (
                    <Button
                      key={key}
                      color="secondary"
                      variant="contained"
                      onClick={() => navigate(page.href)}
                      className="action-btn"
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page.title}
                    </Button>
                  ))
                : ""}
            </List>
            <Divider />
            <List className="action-item-nav-btn">
              {rightAlignedPages.map((page, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    disabled={!page.href}
                    onClick={() => {
                      if (page.href) navigate(page.href);
                    }}
                  >
                    <ListItemText primary={page.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {pages.map((page, index) => (
                <ListItem key={index} disablePadding>
                  {page.href === "checklist" ? (
                    <ListItemButton disabled={!page.href} onClick={handleOpen}>
                      <ListItemText primary={page.title} />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      disabled={!page.href}
                      onClick={() => {
                        if (page.href) navigate(page.href);
                      }}
                    >
                      <ListItemText primary={page.title} />
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
            <Box textAlign={"center"} pt={2} pr={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => toggleDrawer(anchor, false)}
                sx={{
                  color: "red",
                  borderColor: "red",
                  "&:hover": {
                    borderColor: "red",
                    background:"#fff",
                    color: "red",
                  },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        );}

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    return (
        <AppBar color="transparent" position="static" className="no-box-shadow transparent-bar">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {showTitle && 
                        <div 
                            className="logo-wrapper"
                            onClick={() => navigate(dashboardRouteConstants.HOME)} 
                            style={{ cursor: 'pointer' }}>
                            <img src={require('../../../assets/images/logo-title.png')} alt="logo" />
                        </div>}
                    {/* {showTitle && <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => navigate("/")}
                        sx={{
                            mr: 2,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {appTitle}
                    </Typography>} */}

                    <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex", md: "flex" } }}>
                        {pages.map((page, index) => (
                            <Button
                                key={index}
                                onClick={() => {
                                    if (page.href) navigate(page.href)
                                }}
                                disabled={!(page.href)}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {/* {page.title} */}
                            </Button>
                        ))}
                    </Box>
                    <Box className="action-item-nav-btn" sx={{ flexGrow: 0, display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}>
                        {published ? 
                            <Button
                                variant="contained"
                                color="success"
                                className="nav-icon-btn"
                                endIcon={<OpenInNewIcon 
                                    className="nav-icon-svg"
                                    onClick={e => {
                                        e.stopPropagation()
                                        window.open(`https://${websiteUrl}.webbieshop.com`, "_blank").focus()
                                    }}
                                    style={{color:"white"}} 
                                />}
                                onClick={handlePublish}
                                sx={{ my: 2, color: "white", cursor: 'default' }}
                            >
                                Published
                            </Button>
                            //     <CustomButton
                            //     variant="contained"
                            //     color="success"
                            //     onClick={handlePublish}
                            //     sx={{ my: 2, color: 'white', cursor: 'default' }}
                            //   >
                            //     Published
                            //     <OpenInNewIcon
                            //       onClick={(e) => {
                            //         e.stopPropagation();
                            //         window.open(`https://google.com`, '_blank').focus();
                            //       }}
                            //       sx={{ color: 'inherit' }} // Use inherit to inherit the color from the parent button
                            //     />
                            //   </CustomButton>
                            : progress === 100 ?
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handlePublish}
                                sx={{ my: 2, color: "white" }}
                            >
                                Publish
                            </Button>
                            : <CircularProgressWithLabel value={progress} /> }
                    </Box>

                    <Box className="action-item-nav-btn" sx={{ flexGrow: 0, display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}>
                        {rightAlignedPages.map((page, index) => (
                            <Button
                                key={index}
                                onClick={() => {
                                    if (page.href) navigate(page.href)
                                }}
                                disabled={!(page.href)}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>

                    <Box className="action-item-nav-btn" sx={{ flexGrow: 0, display: { xs: "none", sm: "none", md: "flex" } }}>
                        {actionItems.map((page, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                color="secondary"
                                onClick={() => navigate(page.href)}
                                className="action-btn"
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>


                    {/* ********************** Mobile View ********************** */}
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }} />

                    <Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "flex", md: "flex", lg: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleDrawer(anchor, true)}
                            color="inherit"
                        >
                            <MenuIcon sx={{color:"#fff"}} />
                        </IconButton>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            className="drawer-wrapper-right"
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default TransparentAppBar;
