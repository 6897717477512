import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react'
import { Card, DialogContent, Divider, FormControl, FormLabel } from '@mui/material';
import OTP from 'components/Inputs/OTP';
import { enqueueSnackbar } from 'notistack';
import { APIRouteConstants, authRouteConstants } from 'constants/routeConstants';
import _ from 'lodash';
import axiosInstance from "configs/axiosConfig";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const VerifiyOtp = () => {
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState('')
  const loggedInUser = useSelector(state => state.userDetails);
  const { user } = loggedInUser
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.is_mail_verified == false) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [user])


  const doVerifiy = async () => {
    const payload = {
      email: user?.email,
      otp: otp
    }
    let res = await axiosInstance
      .post(APIRouteConstants.AUTH.EMAILOTPVERIFY, payload)

    if (res === false) {
      setOpen(false)
      return
    }


    let { data, response } = res;
    if (response
      && response.status === 400
      && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
      }
    }
    if (data && data.success) {
      setOpen(false)
      enqueueSnackbar('OTP verified successfully', { variant: 'success' });
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F5F5F5',
    boxShadow: 24,
    p: 2,
    borderRadius: 5,
    outline: 'none'
  };


  return (

    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h4 className='title-text' style={{ marginBottom: '12px', textAlign: "center" }}>To proceed, please verify your email <br /> by  entering the OTP sent to your<br /> registered email.</h4>
          <Divider />
          <DialogContent >
            <div className='form-wrapper'>
              <FormLabel className='form-label'>

                <h4 className='label-sec-text'>

                </h4>
              </FormLabel>
              <FormControl className='form-control'>
                <OTP
                  style={{ marginTop: '1rem' }}
                  separator={<span>-</span>}
                  value={otp}
                  onChange={setOtp}
                  length={5} />
              </FormControl>
            </div>
          </DialogContent>
          <div style={{ marginTop: '1rem', marginBottom: '1rem', marginLeft: '18%' }} className='action-wrapper'>
            <Button
              sx={{ width: 'fit-content', mr: 1 }}
              variant="contained"
              onClick={() => doVerifiy()}
              disabled={otp?.length < 5}
              color="secondary">Verify</Button>
            <Button
              sx={{ width: 'fit-content' }}
              variant="outlined"
              onClick={() => navigate(authRouteConstants.LOGOUT)}
              color="secondary">Logout</Button>
          </div>
          {/* </Card> */}
        </Box>
      </Modal>
    </div>
  )
}

export default VerifiyOtp