import React from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link, useNavigate } from "react-router-dom";
function Partnerv1() {
  const navigate = useNavigate();
  return (
    <WithFooter>
      <div className="partnerspage">
        <div className="partnerspage__hero">
          <div className="partnerspage__hero__herosection">
            <div className="partnerspage__hero__content">
              <div className="partnerspage__hero__content__div">
                <div className="partnerspage__hero__content__div__left">
                  <div className="partnerspage__hero__content__div__left__text">
                    <div className="partnerspage__hero__content__div__left__text__heading">
                      Become Our <br />
                      Partner
                    </div>
                    <div className="partnerspage__hero__content__div__left__text__content">
                      <div className="partnerspage__hero__content__div__left__text__content__top">
                        We've combined creating an online store, payment,
                        shipping and shop management into a single, easy-to-use
                        platform. Partner with us to grow and make more money.
                        Unleash that Entrepreneurial spirit in you
                      </div>
                      <div className="partnerspage__hero__content__div__left__text__button">
                        <Link to={"/contact"}>Contact Us</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partnerspage__hero__content__div__image">
                  <img src="images/partners-hero-left-bg.png" alt="bg image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="partnerspage__container">
          <div className="partnerspage__container__div">
            <div className="partnerspage__container__div__para">
              <div className="partnerspage__container__div__para__box">
                <img src="images/partners-img-1.svg" alt="" />
              </div>
              <div className="partnerspage__container__div__para__equal">=</div>
              <div className="partnerspage__container__div__para__box">
                <img src="images/partners-img-2.svg" alt="" />
              </div>
              <div className="partnerspage__container__div__para__equal">=</div>
              <div className="partnerspage__container__div__para__box">
                <img src="images/partners-img-3.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="partnerspage__container__welcomediv">
            <div className="partnerspage__container__welcomediv__title">
              <span></span>
              <span>Everyone Is Welcome</span>
              <span></span>
            </div>
            <div className="partnerspage__container__welcomediv__div1">
              <div>Freelancers</div>
              <div>Digital Agency</div>
              <div>Bloggers</div>
            </div>
            <div className="partnerspage__container__welcomediv__div2">
              <div>Influencers</div>
              <div>Small Businesses</div>
              <div>Side Income Lovers :</div>
            </div>
          </div>
          <div className="aboutpage__container__trustdiv">
            <div className="aboutpage__container__trustdiv__left">
              <div className="aboutpage__container__trustdiv__left__title">
                <span>TRUSTED AND </span>
                <span>SUPPORTED BY </span>
              </div>
              <div className="aboutpage__container__trustdiv__left__images">
                <div className="aboutpage__container__trustdiv__left__images__top">
                  <div>
                    <img src="images/brandimagewhite1.svg" alt="brand image" />
                  </div>
                  <div>
                    <img src="images/brandimagewhite2.svg" alt="brand image" />
                  </div>
                </div>
                <div className="aboutpage__container__trustdiv__left__images__top">
                  <div>
                    <img
                      src="images/brandimagewhite3.svg"
                      className="wid80"
                      alt="brand image"
                    />
                  </div>
                  <div>
                    <img src="images/brandimagewhite4.svg" alt="brand image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutpage__container__trustdiv__right">
              <div>
                <span> OPEN YOUR DIGITAL </span>
                <span> STORE NOW </span>
              </div>
              <div className="aboutpage__container__trustdiv__right__nextbtn">
                <div className="aboutpage__container__trustdiv__right__nextbtn__imgdiv">
                  <img
                    onClick={() => navigate("/auth/signup")}
                    src="images/rightarrowpink.svg"
                    alt="right icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="homepage__support">
        <div className="homepage__support__div">
          <div className="homepage__support__div__card">
            <div className="homepage__support__div__card__image">
              <img src="images/supportimg1.svg" alt="Image" />
              <div className="roundshape"></div>
            </div>
            <div className="homepage__support__div__card__content">
              <div>Responsive Support</div>
              <div>
                Prompt assistance and swift resolution to any inquiries,
                concerns, or issues.
              </div>
            </div>
            <div className="homepage__support__div__card__nextbtn">
              <div className="homepage__support__div__card__nextbtn__imgdiv">
                <i className="bi bi-arrow-right"></i>
              </div>
            </div>
          </div>
          <div className="homepage__support__div__card">
            <div className="homepage__support__div__card__image">
              <img src="images/supportimg2.svg" alt="Image" />
              <div className="roundshape"></div>
            </div>
            <div className="homepage__support__div__card__content">
              <div>Simplified Marketing Tools</div>
              <div>
                Streamline promotional efforts with easy to use and efficient
                marketing tools.
              </div>
            </div>
            <div className="homepage__support__div__card__nextbtn">
              <div className="homepage__support__div__card__nextbtn__imgdiv">
                <i className="bi bi-arrow-right"></i>
              </div>
            </div>
          </div>
          <div className="homepage__support__div__card">
            <div className="homepage__support__div__card__image">
              <img src="images/supportimg3.svg" alt="Image" />
              <div className="roundshape"></div>
            </div>
            <div className="homepage__support__div__card__content">
              <div>Mobile Optimized</div>
              <div>
                A highly responsive interface for effortless mobile shopping.
              </div>
            </div>
            <div className="homepage__support__div__card__nextbtn">
              <div className="homepage__support__div__card__nextbtn__imgdiv">
                <i className="bi bi-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithFooter>
  );
}

export default Partnerv1;
