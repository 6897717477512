import React from "react";
import { Box } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";

// import '@splidejs/react-splide/css';

// or other themes
// import '@splidejs/react-splide/css/skyblue';
import "@splidejs/react-splide/css/sea-green";

// or only core styles
// import '@splidejs/react-splide/css/core';

const Carousel = (props) => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
        }}
        className="carousel-wrapper"
      >
        <Splide
          options={{
            rewind: true,
            perPage: 2,
            perMove: 1,
          }}
          aria-label="My Favorite Images"
        >
          {props.children.map((child) => {
            return <SplideSlide>{child}</SplideSlide>;
          })}
        </Splide>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex", lg: "none" },
        }}
        className="carousel-wrapper"
      >
        <Splide
          options={{
            rewind: true,
            perPage: 2,
            perMove: 1,
          }}
          aria-label="My Favorite Images"
        >
          {props.children.map((child) => {
            return <SplideSlide>{child}</SplideSlide>;
          })}
        </Splide>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
        }}
        className="carousel-wrapper"
      >
        <Splide
          options={{
            rewind: true,
            perPage: 1,
            perMove: 1,
          }}
          aria-label="My Favorite Images"
        >
          {props.children.map((child) => {
            return <SplideSlide>{child}</SplideSlide>;
          })}
        </Splide>
      </Box>
    </>
  );
};

export default Carousel;
