import { useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, ListItem, ListItemIcon, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import BottomDrawer from "components/Drawer/BottomDrawer";
import { useNavigate } from "react-router-dom";
import { useModal } from "utils/context";

const messages = [
  {
    id: 1,
    primary: "Brunch this week?",
    secondary:
      "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: "/static/images/avatar/5.jpg",
  },
  {
    id: 2,
    primary: "Birthday Gift",
    secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
    person: "/static/images/avatar/1.jpg",
  },
  {
    id: 3,
    primary: "Recipe to try",
    secondary:
      "I am try out this new BBQ recipe, I think this might be amazing",
    person: "/static/images/avatar/2.jpg",
  },
  {
    id: 4,
    primary: "Yes!",
    secondary: "I have the tickets to the ReactConf for this year.",
    person: "/static/images/avatar/3.jpg",
  },
  {
    id: 5,
    primary: "Doctor's Appointment",
    secondary:
      "My appointment for the doctor was rescheduled for next Saturday.",
    person: "/static/images/avatar/4.jpg",
  },
  {
    id: 6,
    primary: "Discussion",
    secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
    person: "/static/images/avatar/5.jpg",
  },
  {
    id: 7,
    primary: "Summer BBQ",
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: "/static/images/avatar/1.jpg",
  },
];

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

export default function BottomAppBar({ navItems = [] }) {
  const [openChildMenu, setOpenChildMenu] = useState(false);
  const [childMenu, setChildMenu] = useState([]);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenChildMenu(open);
    if (!open) setChildMenu([]);
  };

  const { handleOpen, handleClose } = useModal();
  return (
    <AppBar
      className="bottom-nav-wrapper"
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0 }}
    >
      <Toolbar className="bottom-nav-toolbar" sx={{pl:0,pr:0}}>
        {navItems.map((item, index) => (
          <>
            {item.path === "info" ? (
              <>
                <ListItem key={index} className="toolbar-item" disablePadding>
                  <ListItemButton
                  sx={{pl:0,pr:0}}
                    onClick={handleOpen}
                    // if (item.path) {
                    //     navigate("");
                    // } else if (item.child.length > 0) {
                    //     setChildMenu(item.child)
                    //     setOpenChildMenu(true)
                    // }
                  >
                    <ListItemIcon className="toolbar-icon" sx={{pl:0,pr:0}} >
                      {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{textAlign:"center"}}
                      className="toolbar-text"
                      primary={item.title}
                    />
                  </ListItemButton>
                </ListItem>
                {index !== navItems.length - 1 ? (
                  <Divider
                    className="toolbar-divider"
                    orientation="vertical"
                    flexItem
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <ListItem key={index} className="toolbar-item" disablePadding>
                  <ListItemButton
                   sx={{pl:0,pr:0}}
                    onClick={() => {
                      if (item.path) {
                        navigate(item.path);
                      } else if (item.child.length > 0) {
                        setChildMenu(item.child);
                        setOpenChildMenu(true);
                      }
                    }}
                  >
                    <ListItemIcon className="toolbar-icon"  sx={{pl:0,pr:0}}>
                      {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      className="toolbar-text"
                      primary={item.title}
                    />
                  </ListItemButton>
                </ListItem>
                {index !== navItems.length - 1 ? (
                  <Divider
                    className="toolbar-divider"
                    orientation="vertical"
                    flexItem
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ))}
      </Toolbar>
      <BottomDrawer
        toggleDrawer={toggleDrawer}
        navItems={childMenu}
        open={openChildMenu}
      />
    </AppBar>
  );
}
