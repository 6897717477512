import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions
} from '@mui/material';
import _ from 'lodash';

import { ReactComponent as Rocket } from 'assets/svgs/rocket.svg';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';

const plans = [{
    image: require("../../../assets/images/parachut.jpg")
}, {
    image: '',
    svg: <Rocket />
}, {
    image: require("../../../assets/images/spaceship.jpg")
}]

const Billing = () => {
    const [subscriptionPlans, setSubscriptionPlan] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState();

    const [upgradedPlan, setUpgradedPlan] = useState();

    const getSubscriptionPlans = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.ACCOUNT.ALL_PLANS)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        console.log('Data ', data, response)

        // if (response 
        //     && response.status === 400
        //     && response.data ) {
        //         for (let i in response.data) {
        //             enqueueSnackbar(_.capitalize(i) + ': '+ response.data[i], { variant: 'error' });
        //         }
        // }

        if (data && data['data']) {
            setSubscriptionPlan(_.sortBy(data['data'], 'duration'))
            setSelectedPlan(data.data[0])
        }
        setLoader(false);
    }

    useEffect(() => {
        getSubscriptionPlans();
    }, []);

    console.log('Selected plan ', selectedPlan)

    return (
        <div className="setup-wrapper website-analytics-wrapper">
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Webbie Plans</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper content-flex'>
                                <span className='content-text'>
                                    Please select the subscription plan that's right for you, you will not be able to publish your website or process orders until you have selected a subscription plan. Please note: Our annual plan offers the best value for money, you will always get the same great features in our different plans, however you will save up to $90 dollars with this plan!
                                </span>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <Grid container className='plan-wrapper' spacing={4}>
                                    {subscriptionPlans.map((plan, i) => (
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Card className={`${selectedPlan.id == plan.id || upgradedPlan == plan.id ? 'selected' : ''} plan-card-wrapper`}>
                                                <div className='detail-wrapper'>
                                                    {plans[i].image ? <img src={plans[i].image} /> : ''}
                                                    {plans[i].svg ? plans[i].svg : ''}
                                                    <span className='plan-text'>{plan.name}</span>
                                                    <div className='separator' />
                                                    <span className='plan-sec-text'>{plan.info}</span><br /><br />
                                                    <Button
                                                        variant="contained"
                                                        disabled={selectedPlan.id == plan.id}
                                                        onClick={() => {
                                                            setUpgradedPlan(plan.id)
                                                        }}
                                                    >
                                                        {'$' + plan.price || 'Free Plan'}
                                                        {plan.duration == 365 ? ' For 12 Months' : plan.duration == 30 ? ' For 1 Month' : ' For 14 Days'}
                                                    </Button>
                                                </div>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                <Button
                                    onClick={() => window.location.href = dashboardRouteConstants.HOME}
                                    variant="outlined">
                                    Skip
                                </Button>
                                <Button disabled={true} variant='contained'>
                                    Proceed to Payment
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Billing