import React from "react";
import { Modal, Box, IconButton, Typography, Divider, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "utils/context";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  bgcolor: "background.paper",
  boxShadow: 10,
  maxHeight: "80vh",
  overflow: "auto",
};

const SimpleModal = ({open, handleClose}) => {
console.log("docsmodal",open)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            pl:3,
            pb:1,
            pt:1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 0, // Add margin-bottom for spacing
            position: "sticky", // Add this line
            top: 0, // Add this line
            backgroundColor: "background.paper", // Add this line
            zIndex: 1, // Add this line
            borderBottom:"1px solid #ccc",
            background:"#e5e5e5"
          }}
        >
          <Typography id="modal-title" component="h4" fontSize={"20px"} color={"#2b2b2b"}>
            Checklist to Get Started and Launch Your Website
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{color:"#2b2b2b"}}/>
          </IconButton>
        </Box>
        
        <Box sx={{ pl:3,pb:3 }}>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            gutterBottom
            mt={2}
          >
            Store Setup Guide
          </Typography>

          <Divider />
          <Typography style={{ fontSize: "16px" }}>
            {" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Store Name.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Store Website Address.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            {" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Logo.
          </Typography>

          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Upload a custom banner in the Website / Website Template section.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Edit/update the pre-filled terms and conditions and privacy policy.
          </Typography>

          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            gutterBottom
            mt={2}
          >
            Shipping Setup
          </Typography>

          <Divider />
          {/* <Typography variant="h6" gutterBottom>
            Options Available
          </Typography> */}
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Shiprazor and own shipping is available.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Create a Shiprazor account if you don't have one.{" "}
            <a href="https://www.shiprazor.com/signup">Sign up here</a>.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            If you have a Shiprazor account, keep the mobile number handy to
            receive and enter the OTP.
          </Typography>

          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Delivery Address Details.
          </Typography>
          {/* <Typography style={{fontSize:"16px"}}>
            [Enter your delivery address details here]
          </Typography> */}

          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            gutterBottom
            mt={2}
          >
            Payment Setup
          </Typography>

          <Divider />

          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Peach Payments and COD is available.
          </Typography>

          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            <a href="https://www.peachpayments.com/resources/get-started">
              Create a Peach Payments account
            </a>
            . if you don't have one.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            If you have a Peach Payments account, keep your Entity ID, Secret
            Token, and password ready.
          </Typography>

          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            gutterBottom
            mt={2}
          >
            Documents Required for Peach Payment:
          </Typography>

          <Divider />
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Proof of legal business registration (CIPC document for South
            African businesses).
          </Typography>
          {/* <Typography style={{ fontSize: "16px" }}>
            {" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Logo
          </Typography> */}

          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Bank account details for receiving payments (bank name, account
            number, branch code).
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            A copy of the business owner’s ID or passport for identity
            verification.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Recent utility bill or bank statement (not older than 3 months)
            showing the business address.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Business contact details (email address OR phone number).
          </Typography>

          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            Product Setup
          </Typography>

          <Divider />
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Category and product details with images.
          </Typography>
{/* 
          <Typography
            style={{ fontSize: "22px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            What You Need to Provide:
          </Typography>
          <Divider />
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
            color={"#3b3b3b"}
          >
            Business Documentation
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            1. <b>Business Registration Certificate:</b> Proof of legal business
            registration (CIPC document for South African businesses).
          </Typography>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
            color={"#3b3b3b"}
          >
            Bank Account Information
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            2. <b>Bank Account Details:</b> Bank name, account number, branch
            code.
          </Typography>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
            color={"#3b3b3b"}
          >
            Identification Documents
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            3. <b>Owner’s ID or Passport:</b> A copy of the business owner’s ID
            or passport for identity verification.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            4. <b>Proof of Address:</b> Recent utility bill or bank statement
            (not older than 3 months) showing the business address.
          </Typography>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
            color={"#3b3b3b"}
          >
            Contact Information
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            5. <b>Business Contact Details:</b> Email address OR phone number.
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            6. <b>Return and Refund Policy:</b> Clear policy on returns and
            refunds for customers specific to your store.
          </Typography>

          <Typography
            style={{ fontSize: "22px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            What WebbieShop Provides:
          </Typography>
          <Divider />
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            Platform Infrastructure
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            A fully functional e-commerce platform with customizable storefront
            templates.
            <br />
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Integrated payment gateway for secure transactions.
            <br />
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            User-friendly product management system.
            <br />
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Analytics and reporting tools to track sales and customer behavior.
            <br />{" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Customer support services to assist with any platform-related
            issues.
          </Typography>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            Legal and Compliance
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            <b>Predefined Privacy Policy:</b> WebbieShop takes care of customer
            data protection and privacy compliance.
            <br />{" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            <b>Terms and Conditions:</b> General terms and conditions for using
            WebbieShop are provided.
          </Typography>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            Marketing and SEO Tools
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Built-in SEO tools to help your products rank better in search
            engines.
            <br />{" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Marketing tools to promote your products within the WebbieShop
            ecosystem.
          </Typography>
          <Typography
            style={{ fontSize: "20px", fontWeight: "bold" }}
            mt={2}
            gutterBottom
          >
            Customer Support and Community
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Access to a community forum for sellers.
            <br />{" "}
            <FiberManualRecordIcon
              fontSize="small"
              style={{ fontSize: "15px", marginRight: 5 }}
            />{" "}
            Customer service support for both sellers and buyers.
          </Typography> */}
          {/* </Box> */}
        </Box>
      <Box textAlign={'right'} pr={1} pb={2}>
        <Button color="error" variant="contained" size="small" onClick={handleClose}>Close</Button>
      </Box>
      </Box>
    </Modal>
  );
};

export default SimpleModal;

// #3b3b3b