import { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    CardActions,
    Button,
    CardMedia
} from '@mui/material';
import _ from 'lodash';

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import Carousel from 'components/Carousel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Analytics = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(true);
    const [salesSummary, setSalesSummary] = useState({});
    const [bestProduct, setBestProductList] = useState([]);
    const navigate = useNavigate()

    const getSalesReport = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.SALES_ANALYTICS)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            setSalesSummary(data)
        }
    }

    const getBestSellingProducts = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.TOP_COLLECTION)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            setBestProductList(data)
        }
        setLoader(false)
    }

    useEffect(() => {
        getSalesReport()
        getBestSellingProducts()
    }, [])

    return (
        <div className="store-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Sales Analytics"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Monitor your store’s performance</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    This section provides you with a snapshot of your store's performance. Using the information below, you will be able to make informed decisions such as increasing stock levels on your best selling products or correlating sales performance for a period against your marketing activities to see which campaigns are producing results. You will also be able to see your total sales, open orders and download reports.
                                </span>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container className='component-grid' spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Total Sales</span><br />
                                <span className='header-sec-text'>Overall customer purchases.</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper content-flex'>
                                <span className='content-text-right figure-text'>
                                    {salesSummary['total_price'] ? '$' + salesSummary['total_price'].toFixed(2) : '$0.00'}
                                </span>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Open Orders</span><br />
                                <span className='header-sec-text'>Customer orders ready to be fulfilled.</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper content-flex content-flex-right'>
                                <span className='content-text-right figure-text'>
                                    {salesSummary['total_count'] || 0}
                                </span>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Best Selling Products</span>
                            </div>
                            <Divider />
                            {bestProduct.length > 0 ?
                                <div className='content-wrapper content-flex content-flex-center'>
                                    <Carousel>
                                        {bestProduct.map(product =>
                                            <DetailCard
                                                product={product}
                                                navigate={navigate} />
                                        )}
                                    </Carousel>
                                </div>
                                : <div className='content-wrapper content-flex content-flex-center'>
                                    <NoData />
                                    <span className='content-text'>
                                        No results found
                                    </span>
                                </div>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

const DetailCard = ({ product, navigate }) => (
    <Card className="slider-card content-card">
        <CardMedia
            sx={{ height: '17rem' }}
            image={product?.product_images[0]?.image}
            title={product?.name}
        />
        <CardContent align="left">
            <Typography gutterBottom component="span">
                {/* {product?.name} */}
                {product.name ? _.truncate(product.name, { length: 30 }) : ''}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {product?.currency}{product?.price}
            </Typography>
        </CardContent>
        <CardActions align="center">
            <Button
                color="success"
                size="small"
                onClick={() => {
                    navigate(dashboardRouteConstants.PRODUCTS, { state: { productId: product.id } })
                }}
                variant="outlined">View</Button>
        </CardActions>
    </Card>
)

export default Analytics