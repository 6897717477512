import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "./GlobalModalContext";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "100%":"55%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 2,
  p: 1,
};

const GlobalModal = () => {
  const { modalData, closeModal } = useModal();
  console.log("modalData", modalData);
  return (
    <Modal open={modalData.open} onClose={closeModal}>
      <Box sx={style}>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography component="h3" fontSize={"0.9rem"} fontWeight={"500"}>
            {modalData.title}.
          </Typography>
          <IconButton aria-label="close" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Divider/>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            width="100%"
            height="100%"
            controls
            src={modalData.videoUrl}
            title={modalData.title}
            // frameBorder="0"
            allowFullScreen
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          sx={{ mt: 1, textTransform: "none" }}
          onClick={closeModal}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default GlobalModal;
