import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProviderT = ({ children }) => {
  const [modalData, setModalData] = useState({
    open: false,
    title: '',
    videoUrl: ''
  });

  const openModal = (title, url) => {
    setModalData({ open: true, title, videoUrl: url });
  };

  const closeModal = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <ModalContext.Provider value={{ modalData, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
