import React from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link } from "react-router-dom";
function Paymentsv1() {
  return (
    <WithFooter>
      <div class="payments">
        <div class="hero">
          <div class="hero__herosection">
            <img
              src="images/homebanner-bg-0.png"
              alt=""
              class="hero__herosection__img1"
            />
            <img
              src="images/homebanner-bg-1.svg"
              alt=""
              class="hero__herosection__img2"
            />
            <div class="hero__content">
              <div class="hero__content__div">
                <div class="hero__content__div__text">
                  <div class="hero__content__div__text__heading">
                    We have your <br />
                    Payments covered
                  </div>
                  <div class="hero__content__div__text__content">
                    We've combined creating an online store, payment, shipping
                    and shop management into a single, easy-to-use platform.
                  </div>
                  <div class="hero__content__div__text__button">
                    <Link to={"/auth/signup"}>Get started — it's free</Link>
                  </div>
                </div>
                <div class="hero__content__div__image">
                  <img src="images/payments-hero-img.svg" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payments__container">
          <div class="payments__container__toppart">
            <div class="payments__container__toppart__heading">
              Business Simplified I Payments Streamlined
            </div>
            {/* <div class="payments__container__toppart__box">
              <div class="payments__container__toppart__box__content">
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Multiple Payment Methods
                    </div>
                    <div class="text">
                      Peach Payments offers all major credit and debit cards,
                      Pay by Bank, Mobicred, Masterpass, 1ForYou, and Apple Pay.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Recurring Payments
                    </div>
                    <div class="text">
                      Peach Payment's card tokenization technology simplifies
                      subscription payments and recurring billing.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
              <div class="payments__container__toppart__box__content">
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Conversion Focused
                    </div>
                    <div class="text">
                      With the highest transaction success rate in the market,
                      Peach Payments ensures you don't miss a sale.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Sell Globally
                    </div>
                    <div class="text">
                      Accept payments in international currencies and expand
                      your business across Africa and the world.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
            </div> */}
            <div class="shipping__container__toppart__box">
              <div class="shipping__container__toppart__box__content">
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Multiple Payment Methods
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        Peach Payments offers all major credit and debit cards,
                        Pay by Bank, Mobicred, Masterpass, 1ForYou, and Apple
                        Pay.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Recurring Payments
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        Peach Payment's card tokenization technology simplifies
                        subscription payments and recurring billing.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
              <div class="shipping__container__toppart__box__content">
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div
                      class="heading"
                      style={{
                        color: "#688ad5",
                      }}
                    >
                      Conversion Focused
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        With the highest transaction success rate in the market,
                        Peach Payments ensures you don't miss a sale.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Sell Globally
                    </div>

                    <div
                      style={{
                        display: "flex",
                        minHeight: "35vh",
                        // alignItems: "center",
                        paddingTop: "15%",
                      }}
                    >
                      <div class="text">
                        Accept payments in international currencies and expand
                        your business across Africa and the world.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="payments__container__partners">
            <div class="payments__container__partners__div">
              <div class="payments__container__partners__div__button">
                <a href="Login2.html">Get started — it's free</a>
              </div>
              <div class="payments__container__partners__div__head">
                <span></span>
                <span>Trusted And Supported By</span>
                <span></span>
              </div>
              <div class="homepage__partners__div__brandlogos">
                <div class="homepage__partners__div__brandlogos__c1">
                  <div>
                    <img src="images/brandimg1.svg" alt="brandimg1" />
                  </div>
                  <div>
                    <img src="images/brandimg2.svg" alt="brandimg2" />
                  </div>
                </div>
                <div class="homepage__partners__div__brandlogos__c1">
                  <div>
                    <img src="images/brandimg3.svg" alt="brandimg3" />
                  </div>
                  <div>
                    <img src="images/brandimg4.svg" alt="brandimg4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homepage__support">
          <div class="homepage__support__div">
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg1.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Responsive Support</div>
                <div>
                  Prompt assistance and swift resolution to any inquiries,
                  concerns, or issues.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg2.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Simplified Marketing Tools</div>
                <div>
                  Streamline promotional efforts with easy to use and efficient
                  marketing tools.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg3.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Mobile Optimized</div>
                <div>
                  A highly responsive interface for effortless mobile shopping.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithFooter>
  );
}

export default Paymentsv1;
