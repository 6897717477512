import { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  FormLabel,
  FormControl,
  Button,
  CardActions,
  TextField,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import _ from "lodash";
import isUrl from "is-url";

import CustomBreadcrumbs from "components/Breadcrumbs";
import ImageUploader from "components/Inputs/ImageUploader";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";
import Loader from "components/Loader";

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const CustomTemplate = ({ breadcrumbs }) => {
  const [logo, setLogo] = useState([]);
  const [dailouge1, setdailouge1] = useState({
    title: "",
    start: "",
    end: "",
  });

  const [banner, setBanner] = useState([]);
  const [loader, setLoader] = useState(false);
  const [promotionalText, setPromotionalText] = useState("");
  const [communityText, setcommunityText] = useState({ text1: "", text2: "" });
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleChange1 = () => {
    setExpanded1(!expanded1);
  };

  const handleChange2 = () => {
    setExpanded2(!expanded2);
  };

  // const getWebDetails = async () => {
  //   setLoader(true);
  //   let res = await axiosInstance.get(
  //     APIRouteConstants.WEBSITE.WEB_LOGO_BANNER
  //   );
  //   if (res === false) {
  //     setLoader(false);
  //     return;
  //   }
  //   let { data, response } = res;

  //   if (data) {
  //     if (data["logo"]) setLogo([data["logo"]]);
  //     if (data["banner"]) setBanner([data["banner"]]);
  //   }
  //   setLoader(false);
  // };
  const getSpecialSaleDetails = async () => {
    setLoader(true);
    let res = await axiosInstance.get(APIRouteConstants.WEBSITE.SPECIAL_SALE);
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (data) {
      setdailouge1({
        title: data.special_sale_text,
        start: data.special_sale_start_date,
        end: data.special_sale_end_date,
      });
    }
    setLoader(false);
  };

  const getPromotionnalDetails = async () => {
    setLoader(true);
    let res = await axiosInstance.get(
      APIRouteConstants.WEBSITE.UPLOAD_CUSTOM_IMAGE
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (data) {
      if (data["shop_image1"]) setLogo([data["shop_image1"]]);
      if (data["shop_image2"]) setBanner([data["shop_image2"]]);
      if (data["shop_text"]) setPromotionalText([data["shop_text"]]);
    }
    setLoader(false);
  };
  const getCommunityDetails = async () => {
    setLoader(true);
    let res = await axiosInstance.get(APIRouteConstants.WEBSITE.COMMUNITY);
    if (res === false) {
      setLoader(false);
      // return;
    }
    let { data, response } = res;

    if (data) {
      if (data?.data["promo_text1"]) {
        setcommunityText((prevState) => ({
          ...prevState,
          text1: data?.data["promo_text1"],
        }));
      }
      if (data?.data["promo_text2"]) {
        setcommunityText((prevState) => ({
          ...prevState,
          text2: data?.data["promo_text2"],
        }));
      }
    }
    setLoader(false);
  };

  // console.log("updateSpecialSaleDetails",dailouge1,logo,banner,)
  const updateSpecialSaleDetails = async () => {
    // if (_.isEmpty(logo)) {
    //   enqueueSnackbar("Logo is mandatory", { variant: "error" });
    //   return;
    // }
    setLoader(true);
    let formData = {
      special_sale_text: dailouge1.title,
      special_sale_end_date: dailouge1.end,
      special_sale_start_date: dailouge1.start,
    };
    console.log("formadata", formData);

    const res = await axiosInstance({
      method: "post",
      url: APIRouteConstants.WEBSITE.SPECIAL_SALE,
      data: formData,
    });
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }
    if (!_.isEmpty(data)) {
      enqueueSnackbar("Details updated successfully", { variant: "success" });
    }
    setLoader(false);
  };
  const updateCommunityDetails = async () => {
    // if (_.isEmpty(logo)) {
    //   enqueueSnackbar("Logo is mandatory", { variant: "error" });
    //   return;
    // }
    setLoader(true);
    let formData = {
      promo_text1: communityText.text1,
      promo_text2: communityText.text2,
    };
    console.log("formadata", formData);

    const res = await axiosInstance({
      method: "post",
      url: APIRouteConstants.WEBSITE.COMMUNITY,
      data: formData,
    });
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }
    if (!_.isEmpty(data)) {
      enqueueSnackbar("Details updated successfully", { variant: "success" });
    }
    setLoader(false);
  };
  const updatePromotionnalDetails = async () => {
    if (_.isEmpty(logo)) {
      enqueueSnackbar("Logo is mandatory", { variant: "error" });
      return;
    }
    setLoader(true);

    let formData = new FormData();
    if (logo[0] && !isUrl(logo[0])) {
      formData.append("shop_image1", logo[0], logo[0].fileName);
    }
    if (banner[0] && !isUrl(banner[0]))
      formData.append("shop_image2", banner[0], banner[0].fileName);
    formData.append("shop_text", promotionalText);

    const res = await axiosInstance({
      method: "post",
      url: APIRouteConstants.WEBSITE.UPLOAD_CUSTOM_IMAGE,
      data: formData,
    });

    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }
    if (!_.isEmpty(data)) {
      enqueueSnackbar("Details updated successfully", { variant: "success" });
    }
    setLoader(false);
  };

  useEffect(() => {
    getSpecialSaleDetails();
    getPromotionnalDetails();
    getCommunityDetails();
  }, []);

  console.log("updateSpecialSaleDetails", communityText);
  const handleUpdate = (sectionNumber) => {
    // Update logic for each section
    switch (sectionNumber) {
      case 1:
        // Update section 1
        break;
      case 2:
        // Update section 2
        break;
      case 3:
        // Update section 3
        break;
      case 4:
        // Update section 4
        break;
      default:
        break;
    }
  };

  const handleDailouge1Change = (e) => {
    const { name, value } = e.target;
    setdailouge1({
      ...dailouge1,
      [name]: value,
    });
  };

  const handlepromotionaltext = (e) => {
    setPromotionalText(e.target.value);
  };
  const handlecommunitytext = (e) => {
    const { name, value } = e.target;
    setcommunityText({
      ...communityText,
      [name]: value,
    });
  };
  // console.log("handlecommunitytext",communityText)

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div className="store-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Website Customization"} />
      </div>
      <Grid container className="component-grid">
        <Grid item xs={12}>
          <Card className="dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">
                  Customize your website landing page
                </span>
              </div>
              <Divider />
              <div className="content-wrapper">
                <span className="content-text" style={{ fontSize: "16px" }}>
                  Customize your website with promotional banners, messages for
                  the community, and much more.
                </span>
                <br />
                <br />
                {/* <span className="content-text">
                  Add your logo to your website and customise the colours on
                  your store template here.
                </span> */}
              </div>

              <Grid
                container
                className="dashboard-form-container"
                spacing={0}
                display={"flex"}
                flexDirection={"column"}
              >
                <Accordion
                  sx={{
                    mb: 3,
                    pt: 1,
                    pb: 1,
                    "&::before": {
                      backgroundColor: "#f7f7f7",
                    },
                  }}
                  expanded={expanded}
                  onChange={handleChange}
                >
                  <AccordionSummary
                    expandIcon={
                      <Box display="flex" alignItems="center">
                        {!expanded ? (
                          <Button
                            startIcon={<AddIcon />}
                            size="small"
                            color="secondary"
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                          >
                            Expand
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            startIcon={<RemoveIcon />}
                            sx={{ textTransform: "none" }}
                          >
                            Collapse
                          </Button>
                        )}
                      </Box>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "none",
                      },
                    }}
                  >
                    <Typography>Special Sale</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: { md: "flex" } }}
                    >
                      <Grid item md={6} sm={12} xs={12} sx={{ padding: 1 }}>
                        <div className="form-wrapper">
                          <FormLabel className="form-label">
                            <span className="label-text">
                              Special Sale Title
                            </span>
                          </FormLabel>
                          <FormControl className="form-control">
                            <TextField
                              value={dailouge1.title}
                              onChange={handleDailouge1Change}
                              size="medium"
                              fullWidth
                              id="title"
                              name="title"
                              label="Title"
                              variant="outlined"
                              InputProps={{
                                style: {
                                  fontSize: "0.9rem",
                                },
                              }}
                            />
                          </FormControl>
                        </div>
                      </Grid>

                      <Grid item md={6} sm={12} xs={12} sx={{ padding: 1 }}>
                        <div className="form-wrapper">
                          <FormLabel className="form-label">
                            <span className="label-text">Duration</span>
                          </FormLabel>
                          <FormControl className="form-control">
                            <TextField
                              value={dailouge1.start}
                              onChange={handleDailouge1Change}
                              size="medium"
                              id="start"
                              name="start"
                              label="Start"
                              type="date"
                              variant="outlined"
                              InputProps={{
                                style: {
                                  fontSize: "0.9rem",
                                },
                                inputProps: {
                                  min: currentDate,
                                },
                              }}
                            />
                          </FormControl>
                          <FormControl className="form-control">
                            <TextField
                              value={dailouge1.end}
                              onChange={handleDailouge1Change}
                              size="medium"
                              sx={{ marginTop: 2 }}
                              type="date"
                              id="end"
                              name="end"
                              label="End"
                              variant="outlined"
                              InputProps={{
                                style: {
                                  fontSize: "0.9rem",
                                },
                              }}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                    <Button
                      sx={{ margin: 1 }}
                      variant="contained"
                      color="secondary"
                      onClick={updateSpecialSaleDetails}
                      size="small"
                    >
                      Update
                    </Button>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{
                    mb: 3,
                    pt: 1,
                    pb: 1,
                    "&::before": {
                      backgroundColor: "#f7f7f7",
                    },
                  }}
                  expanded={expanded1}
                  onChange={handleChange1}
                >
                  <AccordionSummary
                    expandIcon={
                      <Box display="flex" alignItems="center">
                        {!expanded1 ? (
                          <Button
                            startIcon={<AddIcon />}
                            size="small"
                            color="secondary"
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                          >
                            Expand
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            startIcon={<RemoveIcon />}
                            sx={{ textTransform: "none" }}
                          >
                            Collapse
                          </Button>
                        )}
                      </Box>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "none",
                      },
                    }}
                  >
                    <Typography>Promotion</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid sx={{ display: { md: "flex" } }}>
                      <Grid item md={6} sm={12} xs={12} sx={{ p: { md: 1 } }}>
                        <div className="form-wrapper">
                          <FormLabel className="form-label">
                            <span className="label-text">
                              Upload left Banner
                            </span>
                            <br />
                            <span className="label-sec-text">
                              This is the logo we will display for your online
                              store.
                            </span>
                          </FormLabel>
                          <FormControl className="form-control">
                            <ImageUploader
                              imageList={logo}
                              setImageList={setLogo}
                              id="logo"
                              name="logo"
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12} sx={{ p: { md: 1 } }}>
                        <div className="form-wrapper">
                          <FormLabel className="form-label">
                            <span className="label-text">
                              Upload a Right banner (optional)
                            </span>
                            <br />
                            <span className="label-sec-text">
                              Design and upload a banner for your Shop page.
                            </span>
                          </FormLabel>
                          <FormControl className="form-control">
                            <ImageUploader
                              id="banner"
                              name="banner"
                              imageList={banner}
                              setImageList={setBanner}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} sx={{ p: { md: 1 } }}>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          <span className="label-text">
                            Add promotion text here
                          </span>
                          <br />
                          {/* <span className="label-sec-text">
                            Add promotion text here
                          </span> */}
                        </FormLabel>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            multiline
                            rows={2}
                            value={promotionalText}
                            onChange={handlepromotionaltext}
                            size="small"
                            InputProps={{
                              style: {
                                fontSize: "0.9rem",
                              },
                            }}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Button
                      sx={{ m: { md: 1 } }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={updatePromotionnalDetails}
                    >
                      Update
                    </Button>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{
                    mb: 3,
                    pt: 1,
                    pb: 1,
                    "&::before": {
                      backgroundColor: "#f7f7f7",
                    },
                  }}
                  expanded={expanded2}
                  onChange={handleChange2}
                >
                  <AccordionSummary
                    expandIcon={
                      <Box display="flex" alignItems="center">
                        {!expanded2 ? (
                          <Button
                            startIcon={<AddIcon />}
                            size="small"
                            color="secondary"
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                          >
                            Expand
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            startIcon={<RemoveIcon />}
                            sx={{ textTransform: "none" }}
                          >
                            Collapse
                          </Button>
                        )}
                      </Box>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "none",
                      },
                    }}
                  >
                    <Typography>Community</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item md={6} sm={12} xs={12}>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          {/* <span className="label-text">Upload your logo</span>
                          <br /> */}
                          <span className="label-sec-text">
                            Add Community heading text here
                          </span>
                        </FormLabel>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            multiline
                            rows={2}
                            name="text1"
                            id="text1"
                            value={communityText.text1}
                            onChange={handlecommunitytext}
                            size="small"
                            InputProps={{
                              style: {
                                fontSize: "0.9rem",
                              },
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          {/* <span className="label-text">Upload your logo</span>
                          <br /> */}
                          <span className="label-sec-text">
                            Add Community middle text here
                          </span>
                        </FormLabel>
                        <FormControl className="form-control">
                          <TextField
                            fullWidth
                            multiline
                            rows={2}
                            name="text2"
                            id="text2"
                            value={communityText.text2}
                            onChange={handlecommunitytext}
                            size="small"
                            InputProps={{
                              style: {
                                fontSize: "0.9rem",
                              },
                            }}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    {/* <Grid item md={6} sm={12} xs={12}>
                      <div className="form-wrapper">
                        <FormLabel className="form-label">
                          <span className="label-text">
                            Upload a custom banner (optional)
                          </span>
                          <br />
                          <span className="label-sec-text">
                            Design and upload a banner for your Shop page.
                          </span>
                        </FormLabel>
                        <FormControl className="form-control">
                          <ImageUploader
                            id="banner"
                            name="banner"
                            imageList={banner}
                            setImageList={setBanner}
                          />
                        </FormControl>
                      </div>
                    </Grid> */}
                    <Button
                      sx={{ m: { md: 1 } }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={updateCommunityDetails}
                    >
                      Update
                    </Button>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Divider />
            </CardContent>
            <CardActions className="footer-wrapper">
              {/* <div className="action-wrapper"> */}
              {/* <Button
                  variant="contained"
                  onClick={updateWebDetail}
                  color="secondary"
                >
                  Update
                </Button> */}
              {/* </div> */}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomTemplate;
