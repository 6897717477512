import React, { useState } from "react";
import axiosInstance from "configs/axiosConfig";
import {
  APIRouteConstants,
  authRouteConstants,
} from "constants/routeConstants";
import _ from "lodash";

import {
  Card,
  Grid,
  FormControl,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  Select,
} from "@mui/material";

// import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import Loader from "components/Loader";
import GoogleIcon from "@mui/icons-material/Google";
import LockIcon from "@mui/icons-material/Lock";
import MobileNumber from "components/Inputs/MobileNumber";
import { countryCode } from "constants/appData/filters";
import { GLOBAL_COUNTRY_CODE } from "constants/appConstants";
import { Link } from "react-router-dom";

const SignUp = ({ setOpenPrivacyPolicy, setOpenTermsAndCondition }) => {
  // console.log('Props received ', setOpenTermsAndCondition, setOpenTermsAndCondition)
  const [loader, setLoader] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      password: "",
      country_code: GLOBAL_COUNTRY_CODE,
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const doCreateUser = async (formData) => {
    setLoader(true);
    formData = {
      ...formData,
      country_code: formData.country_code.dial_code,
      username: formData.email,
    };
    let res = await axiosInstance.post(APIRouteConstants.AUTH.SIGNUP, formData);

    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;
    if (response && response.status === 400 && response.data) {
      if ((response?.data)) {
        for (let i in response.data) {
          setLoader(false);
          enqueueSnackbar(response.data[i][0], { variant: "error" });
          // enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
        }
      }
      else if (response.data.message) {
        setLoader(false);
        enqueueSnackbar(response.data.message, { variant: "error" });
      } else if (response.data.mobile_number) {
        setLoader(false);
        enqueueSnackbar(response.data.mobile_number[0], { variant: "error" });
      }
      else if (response.data.email) {
        setLoader(false);
        enqueueSnackbar(response.data.email[0], { variant: "error" });
      }
    }

    if (response && response.status === 404 && response.data) {
      setLoader(false)
      enqueueSnackbar("Something went wrong", {
        variant: "success",
      });

    }

    if (data && data.success && data.message) {
      setLoader(false);
      enqueueSnackbar(data.message + " Please Login to continue", {
        variant: "success",
      });
      window.location.href = authRouteConstants.LOGIN;
    }
  };

  return (
    <div className="login-wrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontSize: "1.4rem",
            fontWeight: "600",
            paddingBottom: "20px",
          }}
        >
          Launch your business in 3, 2, 1…
        </p>
      </div>
      <Loader open={loader} />
      <Card className="card-wrapper">
        <span className="form-label">Get Started with a Free Account!</span>
        <form onSubmit={handleSubmit(doCreateUser)}>
          <Grid container className="form-container">
            <Grid item sm={12} className="form-wrapper">
              <FormControl className="form-control" fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  placeholder="Enter First Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="First Name"
                  {...register("first_name", { required: true, maxLength: 20 })}
                  error={errors.first_name?.type}
                  helperText={
                    errors.first_name?.type === "required" &&
                    "First name is required"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <FormControl className="form-control" fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  placeholder="Enter Last Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Last Name"
                  {...register("last_name", { required: true, maxLength: 20 })}
                  error={errors.last_name?.type}
                  helperText={
                    errors.last_name?.type === "required" &&
                    "Last name is required"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <FormControl className="form-control" fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  placeholder="Enter Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Email"
                  {...register("email", {
                    required: true,
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                  })}
                  error={errors.email?.type}
                  helperText={
                    (errors.email?.type === "required" &&
                      "Email is required") ||
                    (errors.email?.type === "pattern" && "Email is invalid")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <FormControl className="form-control" fullWidth>
                <TextField
                  margin="dense"
                  id="name"
                  placeholder="Enter Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Password"
                  {...register("password", {
                    required: true,
                    minLength: 6,
                    maxLength: 20,
                  })}
                  error={errors.password?.type}
                  helperText={
                    (errors.password?.type === "required" &&
                      "Password is required") ||
                    (errors.password?.type === "minLength" &&
                      "Password should be atleast 6 character in length") ||
                    (errors.password?.type === "maxLength" &&
                      "Password should not be more than 20 character in length")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <FormControl fullWidth className="select-wrapper">
                <Controller
                  control={control}
                  name="mobile_number"
                  rules={{ required: true, maxLength: 10, minLength: 9 }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      margin="dense"
                      id="name"
                      placeholder="Mobile Number"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={onChange}
                      value={value}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select-input"
                              label="Status"
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                              }}
                              renderValue={(selected) => (
                                <div className="dial-code-wrapper">
                                  <img src={selected.flag} />
                                  {"+" + selected.dial_code}
                                </div>
                              )}
                              defaultValue={GLOBAL_COUNTRY_CODE}
                              {...register("country_code", { required: true })}
                              error={
                                getValues()["mobile_number"] &&
                                errors.country_code?.type
                              }
                            >
                              {countryCode.map((code, index) => (
                                <MenuItem
                                  className="dial-code-wrapper"
                                  value={code}
                                >
                                  <img src={code.flag} />
                                  {"+" + code.dial_code}
                                </MenuItem>
                              ))}
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <span className="error-text">
                  {(errors.mobile_number?.type === "required" &&
                    "Mobile number is required") ||
                    (errors.mobile_number?.type === "minLength" &&
                      "Mobile number must be 9 or more digit in length") ||
                    (errors.mobile_number?.type === "maxLength" &&
                      "Mobile number must be 10 or less in length")}
                  {getValues()["mobile_number"] &&
                    errors.country_code?.type === "required" &&
                    "Country code is required"}
                </span>
              </FormControl>
            </Grid>
            <Grid item sm={12} className="form-wrapper">
              <div className="button-wrapper">
                <Button
                  variant="contained"
                  // href="/"
                  type="submit"
                  sx={{
                    background: "#CD1479",
                    "&:hover": {
                      background: "#CD1479", // The color you want on hover
                    },
                  }}
                >
                  Next: Set up your Store
                </Button>
              </div>
              <div className="link-wrapper">
                <a href="/auth/login">
                  <div
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      justifyContent: "center",
                    }}
                  >
                    <span>
                      If you already have an Webbie account,
                      <span style={{ fontWeight: "bold" }}>Sign in</span>
                    </span>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Card className="sec-card-wrapper">
        {/* <div className="button-wrapper">
                    <Button 
                        variant="outlined"
                        href="/"
                        color="primary"
                        startIcon={<GoogleIcon />}
                    >
                        Sign Up with Google 
                    </Button>
                </div>
                <div className="button-wrapper">
                    <Button 
                        variant="outlined"
                        href="/"
                        color="primary"
                        startIcon={<LockIcon />}
                    >
                        Sign Up with SSO 
                    </Button>
                </div> */}
        <div className="text-wrapper">
          <span>
            By continuing, you agree to the{" "}
            <Link onClick={() => setOpenTermsAndCondition(true)}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link onClick={() => setOpenPrivacyPolicy(true)}>
            Returns & Refunds
            </Link>
            .
          </span>
        </div>
      </Card>
    </div>
  );
};

export default SignUp;
