import React, { useState } from "react";

import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Button,
    Tooltip,
    MenuItem,
    InputBase
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';

import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
}));

function SearchAppBar({ 
  pages = [],
  settings = [], 
  showToggleMenu, 
  appTitle = "Logo", 
  logo, 
  iconItems = [],
  user = null,
  setLoginOpen
}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSearch = () => {
    setShowSearch(!showSearch);
  }

  return (
    <AppBar position="static" className="search-navbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div className="logo-wrapper">
            <img src={require('../../../assets/images/logo.png')} alt="logo" />
          </div>
          <Typography
            variant="h6"
            noWrap
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {appTitle}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

          <Search sx={{ display: { xs: "none", md: "flex" }}} className="search-bar">
            <SearchIconWrapper className="search-icon">
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                className="search-input"
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        
        {/* Display Mobile */}
            {showToggleMenu && 
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: "block", md: "none" },
                        }}
                        >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            }

          {/* {NavLogo ? 
            <NavLogo sx={{ display: { xs: "flex", md: "none" }, mr: 2 }} /> :
            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 2 }} /> } */}
          {!showSearch && <Typography
            variant="span"
            className="logo-title"
            noWrap
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {appTitle}
          </Typography>}

          {!showSearch && <IconButton 
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} 
            aria-label="search" 
            onClick={handleSearch}
            color="inherit">
            <SearchIcon />
          </IconButton> }
        
            {showSearch && <Search sx={{ display: { xs: "flex", md: "none" }, mr: 3}} className="search-bar">
            <SearchIconWrapper className="search-icon">
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                className="search-input"
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                // onEnter={setShowSearch}
                onBlur={handleSearch}
            />
          </Search>}

          {/* <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box> */}
          <Box sx={{ flexGrow: 0, display: 'flex' }}>
            {!user ? <Button sx={{ color: '#fff' }} onClick={setLoginOpen}>
                    Login
                </Button> : 
                iconItems.map((item, i) =>  <Tooltip title={item.helptext}>
                    <Box sx={{ display: { xs: item.isMobileView ? 'flex' : 'none', md: 'flex' } }}>
                        <IconButton onClick={item.handleClick} sx={{ p: 0 }} className="icon-wrapper">
                            {item.icon}
                        </IconButton>
                    </Box>
                </Tooltip>)
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default SearchAppBar;
