import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
// import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
export default function BottomDrawer({ toggleDrawer, open, navItems = [] }) {
  const navigate = useNavigate();

  const list = () => (
    <>
      <Box textAlign={"end"} pt={2} pr={2}>
        <Button onClick={toggleDrawer(false)}>
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{ width: 250 }}
        className="drawer-list-wrapper"
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {navItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <ListItemIcon>
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );

  return (
    <Drawer anchor={"bottom"} open={open} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
  );
}
