import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TablePagination,
    Rating
} from '@mui/material';

import SearchInput from 'components/Search';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import BasicTable from 'components/Tables/BasicTable';

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";

const Reviews = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(false);
    const [allReviews, setReviewList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rating, setRating] = useState('all');
    const [searchString, setSearchString] = useState('');

    const getReviewList = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_REVIEWS, {
            params: {
                page: pageCount + 1,
                rating: rating,
                search: searchString
            },
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data && data.results) {
            setReviewList(data.results);
            setTotalCount(data.count);
            setPageCount(data.current_page - 1);
        }
        setLoader(false)
    }

    useEffect(() => {
        getReviewList()
    }, [pageCount])

    useEffect(() => {
        getReviewList()
    }, [rating])

    const handleChangePage = () => {
        setPageCount(pageCount + 1)
    }

    return (
        <div className="website-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Reviews"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Product reviews</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper content-flex'>
                                <span className='content-text'>
                                    Product reviews will not be shown on your website until you have approved and marked them to show on your website. Click on the manage review button to review and publish.
                                </span>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <Grid container className='filter-wrapper'>
                                    <Grid item sm={2}>
                                        <span className='detail-sec-text'>
                                            Filter by:
                                        </span>
                                    </Grid>
                                    <Grid item sm={2} className='select-wrapper'>
                                        <FormControl fullWidth>
                                            <InputLabel size="small" id="demo-simple-select-label">Rating</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={rating}
                                                label="Status"
                                                size="small"
                                                onChange={(e) => {
                                                    setRating(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={'all'}>
                                                    All
                                                </MenuItem>
                                                <MenuItem value={'5'}>
                                                    <Rating size="small" name="read-only" value={5} readOnly />
                                                </MenuItem>
                                                <MenuItem value={'4'}>
                                                    <Rating size="small" name="read-only" value={4} readOnly />
                                                </MenuItem>
                                                <MenuItem value={'3'}>
                                                    <Rating size="small" name="read-only" value={3} readOnly />
                                                </MenuItem>
                                                <MenuItem value={'2'}>
                                                    <Rating size="small" name="read-only" value={2} readOnly />
                                                </MenuItem>
                                                <MenuItem value={'1'}>
                                                    <Rating size="small" name="read-only" value={1} readOnly />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={2} />
                                    <Grid item sm={2} />
                                    <Grid item sm={4} className='content-flex content-flex-right'>
                                        <SearchInput
                                            value={searchString}
                                            onChange={(e) => {
                                                setSearchString(e.target.value)
                                            }}
                                            onEnter={getReviewList}
                                            onBlur={getReviewList}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            {!loader ?
                                allReviews.length > 0 ?
                                    <Grid container className='dashboard-table-container' spacing={4} wrap='wrap'>
                                        <Grid item md={12} sm={12}>
                                            <BasicTable
                                                rows={allReviews}
                                                headers={[{
                                                    label: 'Product',
                                                    name: 'product_name'
                                                }, {
                                                    label: 'Review',
                                                    name: 'review',
                                                    type: 'custom',
                                                    width: '70%',
                                                    arguments: ['rating', 'review'],
                                                    component: (rating, review) => {
                                                        return (
                                                            <div className='rating-wrapper'>
                                                                <Rating size="large" defaultValue={rating} readOnly />
                                                                <br />
                                                                <span>{review}</span>
                                                            </div>
                                                        )
                                                    }
                                                }, {
                                                    label: 'By Customer',
                                                    name: 'customer_username'
                                                }]}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                component="div"
                                                count={totalCount}
                                                rowsPerPage={totalCount < 10 ? totalCount : 10}
                                                page={pageCount}
                                                onPageChange={handleChangePage}
                                            />
                                        </Grid>
                                    </Grid>
                                    : <div className='content-wrapper content-flex content-flex-center'>
                                        <NoData />
                                        <div className='detail-action-wrapper content-flex content-flex-center'>
                                            <span className='detail-sec-text'>
                                                No results found
                                            </span>
                                        </div>
                                    </div> : ''}
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">

                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Reviews