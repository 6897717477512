import { 
    Select,
    TextField,
    InputAdornment,
    MenuItem,
    InputLabel
} from "@mui/material";
import { GLOBAL_COUNTRY_CODE } from "constants/appConstants";

import { countryCode } from 'constants/appData/filters';

const MobileNumber = ({ register, errors }) => {
    // console.log('Props received ', props)
    return (
        <div className="mobile-input-wrapper">
            {/* <InputLabel id="demo-simple-select-label">Voucher Option</InputLabel> */}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                className="select-input"
                label="Status"
                size="small"
                fullWidth
                sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                }}
                renderValue={(selected) => (
                    <div className="dial-code-wrapper">
                        <img src={selected.flag} />
                        {'+' + selected.dial_code}
                    </div>
                )}
                defaultValue={GLOBAL_COUNTRY_CODE}
                {...register("country_code", { required: true })}
                helperText={errors.country_code?.type === "required" && (
                    'Country code is required'
                )}
            >
                {countryCode.map((code, index) => 
                    <MenuItem className="dial-code-wrapper" value={code}>
                        <img src={code.flag} />
                        {'+' + code.dial_code}
                    </MenuItem>
                )}
            </Select>
            <TextField
                margin="dense"
                placeholder="xxxxxxxxxx"
                type="number"
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: false }}
                {...register("mobile_number", { required: true, maxLength: 10, minLength: 9 })}
                error={errors.mobile_number?.type}
                helperText={(errors.mobile_number?.type === "required" && (
                    'Phone number is required'
                ) || errors.mobile_number?.type === "maxLength" && (
                    'Mobile number must be 10 or less in length'
                ) || errors.mobile_number?.type === "minLength" && (
                    'Mobile number must be 9 or more digit in length'
                ))}
            />
        </div>
    )
}

export default MobileNumber