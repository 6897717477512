export const filterCategory = [
    {
        title: "Categories",
        items: [
            {
                name: "Television",
            },
            {
                name: "Soundbar",
            },
            {
                name: "Amplifier",
            },
        ],
    },
    {
        title: "Brand",
        type: "checkbox",
        items: [
            {
                name: "Samsung",
            },
            {
                name: "Marshall",
            },
            {
                name: "Sony",
            },
            {
                name: "JBL",
            },
        ],
    },
    {
        title: "Price",
        type: "range",
        start: 0,
        end: 1000,
    },
    {
        title: "Color",
        type: "checkbox",
        items: [
            {
                name: "White",
            },
            {
                name: "Black",
            },
            {
                name: "Grey",
            },
            {
                name: "Red",
            },
        ],
    },
    {
        title: "Wired / Wireless",
        type: "checkbox",
        items: [
            {
                name: "Wired",
            },
            {
                name: "Wireless",
            },
        ],
    },
];

export const categoryList = [
    "Topwear",
    "Bottomwear",
    "Footwear",
    "Activewear",
    "Sleepwear",
    "Accessories",
];

// export const countryCode = [{
//     "flag": "https://geo5.net/imagens/Bandeira-da-Africa-do-Sul.png",
//     "name": "South Africa",
//     "dial_code": "27",
//     "code": "SA"
// // },{
// //     "name": "India",
// //     "dial_code": "91",
// //     "emoji": "🇮🇳",
// //     "code": "IN",
// //     "flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f3.svg",
// }]
export const countryCode = [
    {
        "flag": "https://flagcdn.com/w320/dz.png",
        "name": "Algeria",
        "dial_code": "213",
        "code": "DZ"
    },
    {
        "flag": "https://flagcdn.com/w320/ao.png",
        "name": "Angola",
        "dial_code": "244",
        "code": "AO"
    },
    {
        "flag": "https://flagcdn.com/w320/bj.png",
        "name": "Benin",
        "dial_code": "229",
        "code": "BJ"
    },
    {
        "flag": "https://flagcdn.com/w320/bw.png",
        "name": "Botswana",
        "dial_code": "267",
        "code": "BW"
    },
    {
        "flag": "https://flagcdn.com/w320/bf.png",
        "name": "Burkina Faso",
        "dial_code": "226",
        "code": "BF"
    },
    {
        "flag": "https://flagcdn.com/w320/bi.png",
        "name": "Burundi",
        "dial_code": "257",
        "code": "BI"
    },
    {
        "flag": "https://flagcdn.com/w320/cv.png",
        "name": "Cape Verde",
        "dial_code": "238",
        "code": "CV"
    },
    {
        "flag": "https://flagcdn.com/w320/cm.png",
        "name": "Cameroon",
        "dial_code": "237",
        "code": "CM"
    },
    {
        "flag": "https://flagcdn.com/w320/cf.png",
        "name": "Central African Republic",
        "dial_code": "236",
        "code": "CF"
    },
    {
        "flag": "https://flagcdn.com/w320/td.png",
        "name": "Chad",
        "dial_code": "235",
        "code": "TD"
    },
    {
        "flag": "https://flagcdn.com/w320/km.png",
        "name": "Comoros",
        "dial_code": "269",
        "code": "KM"
    },
    {
        "flag": "https://flagcdn.com/w320/cg.png",
        "name": "Congo",
        "dial_code": "242",
        "code": "CG"
    },
    {
        "flag": "https://flagcdn.com/w320/cd.png",
        "name": "Democratic Republic of the Congo",
        "dial_code": "243",
        "code": "CD"
    },
    {
        "flag": "https://flagcdn.com/w320/ci.png",
        "name": "Ivory Coast",
        "dial_code": "225",
        "code": "CI"
    },
    {
        "flag": "https://flagcdn.com/w320/dj.png",
        "name": "Djibouti",
        "dial_code": "253",
        "code": "DJ"
    },
    {
        "flag": "https://flagcdn.com/w320/eg.png",
        "name": "Egypt",
        "dial_code": "20",
        "code": "EG"
    },
    {
        "flag": "https://flagcdn.com/w320/gq.png",
        "name": "Equatorial Guinea",
        "dial_code": "240",
        "code": "GQ"
    },
    {
        "flag": "https://flagcdn.com/w320/er.png",
        "name": "Eritrea",
        "dial_code": "291",
        "code": "ER"
    },
    {
        "flag": "https://flagcdn.com/w320/sz.png",
        "name": "Eswatini",
        "dial_code": "268",
        "code": "SZ"
    },
    {
        "flag": "https://flagcdn.com/w320/et.png",
        "name": "Ethiopia",
        "dial_code": "251",
        "code": "ET"
    },
    {
        "flag": "https://flagcdn.com/w320/ga.png",
        "name": "Gabon",
        "dial_code": "241",
        "code": "GA"
    },
    {
        "flag": "https://flagcdn.com/w320/gm.png",
        "name": "Gambia",
        "dial_code": "220",
        "code": "GM"
    },
    {
        "flag": "https://flagcdn.com/w320/gh.png",
        "name": "Ghana",
        "dial_code": "233",
        "code": "GH"
    },
    {
        "flag": "https://flagcdn.com/w320/gn.png",
        "name": "Guinea",
        "dial_code": "224",
        "code": "GN"
    },
    {
        "flag": "https://flagcdn.com/w320/gw.png",
        "name": "Guinea-Bissau",
        "dial_code": "245",
        "code": "GW"
    },
    {
        "flag": "https://flagcdn.com/w320/ke.png",
        "name": "Kenya",
        "dial_code": "254",
        "code": "KE"
    },
    {
        "flag": "https://flagcdn.com/w320/ls.png",
        "name": "Lesotho",
        "dial_code": "266",
        "code": "LS"
    },
    {
        "flag": "https://flagcdn.com/w320/lr.png",
        "name": "Liberia",
        "dial_code": "231",
        "code": "LR"
    },
    {
        "flag": "https://flagcdn.com/w320/ly.png",
        "name": "Libya",
        "dial_code": "218",
        "code": "LY"
    },
    {
        "flag": "https://flagcdn.com/w320/mg.png",
        "name": "Madagascar",
        "dial_code": "261",
        "code": "MG"
    },
    {
        "flag": "https://flagcdn.com/w320/mw.png",
        "name": "Malawi",
        "dial_code": "265",
        "code": "MW"
    },
    {
        "flag": "https://flagcdn.com/w320/ml.png",
        "name": "Mali",
        "dial_code": "223",
        "code": "ML"
    },
    {
        "flag": "https://flagcdn.com/w320/mr.png",
        "name": "Mauritania",
        "dial_code": "222",
        "code": "MR"
    },
    {
        "flag": "https://flagcdn.com/w320/mu.png",
        "name": "Mauritius",
        "dial_code": "230",
        "code": "MU"
    },
    {
        "flag": "https://flagcdn.com/w320/ma.png",
        "name": "Morocco",
        "dial_code": "212",
        "code": "MA"
    },
    {
        "flag": "https://flagcdn.com/w320/mz.png",
        "name": "Mozambique",
        "dial_code": "258",
        "code": "MZ"
    },
    {
        "flag": "https://flagcdn.com/w320/na.png",
        "name": "Namibia",
        "dial_code": "264",
        "code": "NA"
    },
    {
        "flag": "https://flagcdn.com/w320/ne.png",
        "name": "Niger",
        "dial_code": "227",
        "code": "NE"
    },
    {
        "flag": "https://flagcdn.com/w320/ng.png",
        "name": "Nigeria",
        "dial_code": "234",
        "code": "NG"
    },
    {
        "flag": "https://flagcdn.com/w320/rw.png",
        "name": "Rwanda",
        "dial_code": "250",
        "code": "RW"
    },
    {
        "flag": "https://flagcdn.com/w320/st.png",
        "name": "São Tomé and Príncipe",
        "dial_code": "239",
        "code": "ST"
    },
    {
        "flag": "https://flagcdn.com/w320/sn.png",
        "name": "Senegal",
        "dial_code": "221",
        "code": "SN"
    },
    {
        "flag": "https://flagcdn.com/w320/sc.png",
        "name": "Seychelles",
        "dial_code": "248",
        "code": "SC"
    },
    {
        "flag": "https://flagcdn.com/w320/sl.png",
        "name": "Sierra Leone",
        "dial_code": "232",
        "code": "SL"
    },
    {
        "flag": "https://flagcdn.com/w320/so.png",
        "name": "Somalia",
        "dial_code": "252",
        "code": "SO"
    },
    {
        "flag": "https://flagcdn.com/w320/za.png",
        "name": "South Africa",
        "dial_code": "27",
        "code": "ZA"
    },
    {
        "flag": "https://flagcdn.com/w320/ss.png",
        "name": "South Sudan",
        "dial_code": "211",
        "code": "SS"
    },
    {
        "flag": "https://flagcdn.com/w320/sd.png",
        "name": "Sudan",
        "dial_code": "249",
        "code": "SD"
    },
    {
        "flag": "https://flagcdn.com/w320/tz.png",
        "name": "Tanzania",
        "dial_code": "255",
        "code": "TZ"
    },
    {
        "flag": "https://flagcdn.com/w320/tg.png",
        "name": "Togo",
        "dial_code": "228",
        "code": "TG"
    },
    {
        "flag": "https://flagcdn.com/w320/ug.png",
        "name": "Uganda",
        "dial_code": "256",
        "code": "UG"
    },
    {
        "flag": "https://flagcdn.com/w320/zm.png",
        "name": "Zambia",
        "dial_code": "260",
        "code": "ZM"
    },
    {
        "flag": "https://flagcdn.com/w320/zw.png",
        "name": "Zimbabwe",
        "dial_code": "263",
        "code": "ZW"
    },
    {
        "flag": "https://flagcdn.com/w320/in.png",
        "name": "India",
        "dial_code": "91",
        "code": "IN"
    }
];







export const orderStatus = [{
    label: 'Order Pending',
    value: 1
}, {
    label: 'Confirmed',
    value: 2
}, {
    label: 'Packed',
    value: 3
}, {
    label: 'Shipped',
    value: 4
}, {
    label: 'Delivered',
    value: 5
}, {
    label: 'Return Requested',
    value: 6
}, {
    label: 'Return Confirmed',
    value: 7
}, {
    label: 'Return Completed',
    value: 8
}, {
    label: 'Refund Processed',
    value: 9
}, {
    label: 'Cancelled',
    value: 10
}]
