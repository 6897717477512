import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { APP_NAME, APP_LOGO } from "constants/appConstants";

import DashboardLayout from 'containers/Layouts/DashboardLayout';
import { getAccessToken } from 'utils';
import { updateUserDetail } from 'utils/auth';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from "constants/routeConstants";
import { authRouteConstants } from 'constants/routeConstants';
import { useSelector } from 'react-redux';

const Dashboard = () => {
    const loggedInUser = useSelector(state => state.userDetails);
    const navigate = useNavigate();

    const getUser = async () => {
        let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)

        if (res === false) {
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 401) {
            window.location.href = authRouteConstants.LOGOUT
        }

        if (data && data.success) {
            const user = data.data;
            updateUserDetail(user)
        }
    }

    const getLoggedUser = () => {
        if (!_.isEmpty(loggedInUser)) return loggedInUser;
        const token = getAccessToken()
        if (!token) {
            // window.location.href = authRouteConstants.HOME
            navigate(authRouteConstants.HOME)
            return
        }
        getUser();
    }

    useEffect(() => {
        getLoggedUser();
    }, []);

    // useEffect(() => {
    //     if (!_.isEmpty(loggedInUser)) {
    //         const { user } = loggedInUser
    //         if (!user['storeSetup'])
    //             navigate(dashboardRouteConstants.INITIAL_SETUP)
    //     }
    // }, [loggedInUser])

    return (
        <DashboardLayout appTitle={APP_NAME}>
            <Outlet />
        </DashboardLayout>
    )
}

export default React.memo(Dashboard);