import { ReactComponent as NotFound } from 'assets/svgs/NotFound.svg';

const NaN = () => {
    return (
        <div className="nan-wrapper">
            <NotFound />
        </div>
    )
}

export default NaN