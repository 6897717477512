import React, { useEffect, useState } from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { TERMS_OF_SERVICE } from "constants/appConstants";
import _ from "lodash";
import { DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Loader from "components/Loader";
function Termasandcondistion() {
  const [termsAndCondition, setTermsAndCondition] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTermsAndCondition();
  }, []);

  const getTermsAndCondition = async () => {
    let res = await axios.get(TERMS_OF_SERVICE);
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }

    if (data && data.data && data.data.other_content_blocks) {
      setLoading(false);
      console.log("Terms", data.data.other_content_blocks[0]);
      setTermsAndCondition(data.data.other_content_blocks[0]);
    }
  };
  return (
    <WithFooter>
      {loading ? (
        <Loader />
      ) : (
        <div className="termsandcondtions">
          <div className="terms">
            <div className="terms__container">
              <DialogTitle sx={{ m: 0, pl: 2, fontSize: "1.4rem" }}>
                Webbie Shop {_.get(termsAndCondition, "heading")}
              </DialogTitle>
              <div className="terms__container__content">
                {/* <DialogTitle sx={{ m: 0, pl: 2, fontSize: "1.2rem" }}>
                {/* {_.get(termsAndCondition, "subheading")} */}
                {/* </DialogTitle> */}
                <DialogContent className="dialog-content">
                  <DialogContentText
                    className="policy-wrapper"
                    id="alert-dialog-slide-description"
                  >
                    {/* <span style={{ fontWeight: "600", color: "black" }}>
                    {_.get(termsAndCondition, "subheading")}
                  </span> */}
                    <p style={{ whiteSpace: "pre-line", fontSize: "15px" }}>
                      {_.get(termsAndCondition, "text_content")}
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            </div>
          </div>
        </div>
      )}
    </WithFooter>
  );
}

export default Termasandcondistion;
