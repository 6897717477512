import { useEffect, useState } from 'react';
import {
    Card, 
    Divider,
    Grid,
    CardContent,
    FormLabel,
    FormControl,
    TextField,
    InputAdornment,
    CardActions,
    Select,
    MenuItem
} from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { countryCode } from 'constants/appData/filters';
import CustomBreadcrumbs from "components/Breadcrumbs";
import Loader from 'components/Loader';

const Profile = ({ breadcrumbs }) => {
    const loggedInUser = useSelector(state => state.userDetails);
    const { user } = loggedInUser;
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (_.isEmpty(user)) {
            setLoader(true)
        } else setLoader(false)
    }, [user])

    return (
        <div className="store-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Profile"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent>
                        <div className='header-wrapper'>
                            <span className='header-text'>Profile</span>
                        </div>
                        <Divider />
                        <div className='content-wrapper content-flex'>
                            <span className='content-text'>
                            These are your profile details as the account holder and will be used for password recovery as well as any communication between you and Webbie, and will never be shown to your customers. Please ensure that these details are always up to date.
                            </span>
                        </div>
                        <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                        {/* <Add /> */}
                            <Grid item md={6} sm={12} xs={12}>
                                <div className='form-wrapper'>
                                    <FormLabel className='form-label'>
                                        <span className='label-text'>
                                            First name
                                        </span><br />
                                        <span className='label-sec-text'>
                                        Enter your first name here.
                                        </span>
                                    </FormLabel>
                                    <FormControl className='form-control'>
                                        <TextField
                                            margin="dense"
                                            id="name"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            value={user?.first_name}
                                            InputLabelProps={{ shrink: false }}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <div className='form-wrapper'>
                                    <FormLabel className='form-label'>
                                        <span className='label-text'>
                                            Last Name
                                        </span><br />
                                        <span className='label-sec-text'>
                                            Enter your Last name here.
                                        </span>
                                    </FormLabel>
                                    <FormControl className='form-control'>
                                        <TextField
                                            margin="dense"
                                            id="name"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            value={user?.last_name}
                                            disabled
                                            InputLabelProps={{ shrink: false }}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <div className='form-wrapper'>
                                    <FormLabel className='form-label'>
                                        <span className='label-text'>
                                            Email address
                                        </span><br />
                                        <span className='label-sec-text'>
                                            This email address will be used for all communication.
                                        </span>
                                    </FormLabel>
                                    <FormControl className='form-control'>
                                        <TextField
                                            margin="dense"
                                            id="name"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            value={user?.email}
                                            InputLabelProps={{ shrink: false }}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <div className='form-wrapper'>
                                    <FormLabel className='form-label'>
                                        <span className='label-text'>
                                        Mobile number
                                        </span><br />
                                        <span className='label-sec-text'>
                                        Please enter your contact number here.
                                        </span>
                                    </FormLabel>
                                    <FormControl className='form-control'>
                                        <TextField
                                            margin="dense"
                                            id="name"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            value={user?.mobile_number}
                                            InputLabelProps={{ shrink: false }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        className="select-input"
                                                        label="Status"
                                                        size="small"
                                                        fullWidth
                                                        sx={{
                                                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    border: 0,
                                                                },
                                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                        disabled
                                                        renderValue={(selected) => (
                                                            <div className="dial-code-wrapper">
                                                                <img src={selected.flag} />
                                                                {'+' + selected.dial_code}
                                                            </div>
                                                        )}
                                                        value={user?.country_code ? _.find(countryCode, { dial_code: user.country_code }) : ''}
                                                    >
                                                        {countryCode.map((code, index) => 
                                                            <MenuItem className="dial-code-wrapper" value={code}>
                                                                <img src={code.flag} />
                                                                {'+' + code.dial_code}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Profile