import { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    Card,
    CardContent,
    Divider,
    Grid,
    FormLabel,
    FormControl,
    TextField,
    Button,
    CardActions
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';

const About = ({ breadcrumbs }) => {
    const [aboutUs, setAboutUs] = useState('');
    const [storeName, setStoreName] = useState('');
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const getAboutUs = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_ABOUT_US)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 404) {
            setAboutUs()
            setStoreName('')
        }

        if (data) {
            setAboutUs(data['about'])
            setStoreName(data['store_name'])
        }
        setLoader(false)
    }

    useEffect(() => {
        getAboutUs()
    }, [])

    const updateAboutUs = async () => {
        if (!aboutUs && !storeName) return;
        setLoader(true)
        const res = await axiosInstance({
            method: 'post',
            url: APIRouteConstants.WEBSITE.WEB_ABOUT_US,
            data: {
                store_name: storeName,
                about: aboutUs
            }
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;
        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }
        if (!_.isEmpty(data)) {
            enqueueSnackbar('Details updated successfully', { variant: 'success' });
        }
        setLoader(false)
    }

    const handleUpdate = () => {
        updateAboutUs()
    }

    return (
        <div className="website-analytics-wrapper store-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"About"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent className='content-bottom-wrapper'>
                            <div className='header-wrapper'>
                                <span className='header-text'>About Page</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    Tell us about your business.
                                </span>
                            </div>
                            <Grid container className='form-grid-container dashboard-form-container' spacing={4} wrap='wrap'>
                                {/* <Add /> */}
                                <Grid item md={12} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Store name
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Please enter your store name.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                placeholder="Enter Store Name"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={storeName}
                                                onChange={e => setStoreName(e.target.value)}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                About us page details
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Tell us about your business.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <ReactQuill
                                                className='editor'
                                                value={aboutUs}
                                                onChange={e => setAboutUs(e)}
                                                theme="snow" />
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                <Button variant="contained" color="secondary" onClick={handleUpdate}>
                                    Update
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                        navigate(dashboardRouteConstants.HOME)
                                    }}>
                                    Next {'>'}
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default About



// import { useState, useEffect } from 'react';
// import _ from 'lodash';

// import {
//   Card,
//   CardContent,
//   Divider,
//   Grid,
//   FormLabel,
//   FormControl,
//   TextField,
//   Button,
//   CardActions,
//   Typography,
// } from '@mui/material';
// import { useSnackbar } from 'notistack';

// import CustomBreadcrumbs from "components/Breadcrumbs";
// import axiosInstance from "configs/axiosConfig";
// import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import Loader from 'components/Loader';
// import { useNavigate } from 'react-router-dom';

// const About = ({ breadcrumbs }) => {
//   const [aboutUs, setAboutUs] = useState('');
//   const [storeName, setStoreName] = useState('');
//   const [founderImage, setFounderImage] = useState(''); // For founder or office image
//   const [founderStory, setFounderStory] = useState(''); // For founder story
//   const [loader, setLoader] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const navigate = useNavigate();

//   const getAboutUs = async () => {
//     setLoader(true);
//     let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_ABOUT_US);
//     if (res === false) {
//       setLoader(false);
//       return;
//     }
//     let { data, response } = res;

//     if (response && response.status === 404) {
//       setAboutUs('');
//       setStoreName('');
//       setFounderImage('');
//       setFounderStory(''); // Initialize founder story
//       setBrandLogos(['']);
//     }

//     if (data) {
//       setAboutUs(data['about']);
//       setStoreName(data['store_name']);
//       setFounderImage(data['founder_image'] || ''); // Fetch founder image
//       setFounderStory(data['founder_story'] || ''); // Fetch founder story
//       setBrandLogos(data['brand_logos'] || ['']); // Fetch brand logos
//     }
//     setLoader(false);
//   };

//   useEffect(() => {
//     getAboutUs();
//   }, []);

//   const updateAboutUs = async () => {
//     if (!aboutUs && !storeName) return;
//     setLoader(true);
//     const res = await axiosInstance({
//       method: 'post',
//       url: APIRouteConstants.WEBSITE.WEB_ABOUT_US,
//       data: {
//         store_name: storeName,
//         about: aboutUs,
//         founder_image: founderImage, // Add founder image to the request
//         founder_story: founderStory, // Add founder story to the request
//         brand_logos: brandLogos, // Add brand logos to the request
//       }
//     });

//     if (res === false) {
//       setLoader(false);
//       return;
//     }

//     let { data, response } = res;
//     if (response && response.status === 400 && response.data) {
//       for (let i in response.data) {
//         enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
//       }
//     }
//     if (!_.isEmpty(data)) {
//       enqueueSnackbar('Details updated successfully', { variant: 'success' });
//     }
//     setLoader(false);
//   };

//   const handleAddLogo = () => {
//     if (brandLogos.length < 6) {
//       setBrandLogos([...brandLogos, '']);
//     }
//   };

//   const handleUpdate = () => {
//     updateAboutUs();
//   };


// const [brandLogos, setBrandLogos] = useState([{ file: null, preview: '' }]); // Store files and previews

// // Function to handle image file uploads
// const handleLogoUpload = (e, index) => {
//   const file = e.target.files[0];
//   if (file) {
//     const newLogos = [...brandLogos];
//     newLogos[index] = {
//       file,
//       preview: URL.createObjectURL(file), // Generate a preview URL for the uploaded file
//     };
//     setBrandLogos(newLogos);
//   }
// };


//   return (
//     <div className="website-analytics-wrapper store-analytics-wrapper">
//       <Loader open={loader} />
//       <div className="breadcrumb-wrapper">
//         <CustomBreadcrumbs list={breadcrumbs} name="About" />
//       </div>
//       <Grid container className="component-grid">
//         <Grid item xs={12}>
//           <Card className="dashboard-card-wrapper">
//             <CardContent className="content-bottom-wrapper">
//               <div className="header-wrapper">
//                 <span className="header-text">About Page</span>
//               </div>
//               <Divider />
//               <div className="content-wrapper">
//                 <span className="content-text">Tell us about your business.</span>
//               </div>

//               {/* About Us Form */}
//               <Grid container className="form-grid-container dashboard-form-container" spacing={4} wrap="wrap">
//                 <Grid item md={12} sm={12}>
//                   <div className="form-wrapper">
//                     <FormLabel className="form-label">
//                       <span className="label-text">Store name</span><br />
//                       <span className="label-sec-text">Please enter your store name.</span>
//                     </FormLabel>
//                     <FormControl className="form-control">
//                       <TextField
//                         margin="dense"
//                         placeholder="Enter Store Name"
//                         type="text"
//                         fullWidth
//                         variant="outlined"
//                         size="small"
//                         value={storeName}
//                         onChange={e => setStoreName(e.target.value)}
//                       />
//                     </FormControl>
//                   </div>

//                   <div className="form-wrapper">
//                     <FormLabel className="form-label">
//                       <span className="label-text">About us page details</span><br />
//                       <span className="label-sec-text">Tell us about your business.</span>
//                     </FormLabel>
//                     <FormControl className="form-control">
//                       <ReactQuill
//                         className="editor"
//                         value={aboutUs}
//                         onChange={e => setAboutUs(e)}
//                         theme="snow"
//                       />
//                     </FormControl>
//                   </div>

//                   {/* Founder/Office Image */}
//                   <div className="form-wrapper">
//                     <FormLabel className="form-label" sx={{mt:4}}>
//                       <span className="label-text">Founder or Office Image</span><br />
//                       <span className="label-sec-text">Upload an image of your office or founder.</span>
//                     </FormLabel>
//                     <FormControl className="form-control">
//                       <TextField
//                         margin="dense"
//                         placeholder="Enter Image URL"
//                         type="text"
//                         fullWidth
//                         variant="outlined"
//                         size="small"
//                         value={founderImage}
//                         onChange={e => setFounderImage(e.target.value)}
//                       />
//                     </FormControl>
//                   </div>

//                   {/* Founder Story */}
//                   <div className="form-wrapper">
//                     <FormLabel className="form-label">
//                       <span className="label-text">Founder Story</span><br />
//                       <span className="label-sec-text">Write a detailed story about the founder.</span>
//                     </FormLabel>
//                     <FormControl className="form-control">
//                       <ReactQuill
//                         className="editor"
//                         value={founderStory}
//                         onChange={e => setFounderStory(e)}
//                         theme="snow"
//                       />
//                     </FormControl>
//                   </div>

//                   {/* Brand Logos */}
//                   <div className="form-wrapper">
//     <FormLabel className="form-label" sx={{ mt: 4 }}>
//       <span className="label-text">Associated Brand Logos</span><br />
//       <span className="label-sec-text">Upload up to 5-6 logos of brands you sell or are associated with.</span>
//     </FormLabel>

//     {brandLogos.map((logo, index) => (
//       <div key={index} style={{ marginBottom: '1rem' }}>
//         <FormControl className="form-control">
//           <TextField
//             margin="dense"
//             placeholder={`Enter Logo URL ${index + 1}`}
//             type="file"
//             fullWidth
//             inputProps={{ accept: 'image/png, image/jpeg' }} // Restrict file types to PNG and JPG
//             onChange={e => handleLogoUpload(e, index)}
//           />
//           {logo.preview && (
//             <div style={{ marginTop: '0.5rem' }}>
//               <img src={logo.preview} alt={`Logo Preview ${index + 1}`} style={{ maxHeight: '100px', maxWidth: '100px' }} />
//             </div>
//           )}
//         </FormControl>
//       </div>
//     ))}

//     {brandLogos.length < 6 && (
//       <Button variant="outlined" color="primary" onClick={handleAddLogo}>
//         Add Another Logo
//       </Button>
//     )}
//   </div>
//                 </Grid>
//               </Grid>
//             </CardContent>
//             <CardActions className="footer-wrapper">
//               <div className="action-wrapper">
//                 <Button variant="contained" color="secondary" onClick={handleUpdate}>
//                   Update
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   style={{ backgroundColor: 'white' }}
//                   onClick={() => {
//                     navigate(dashboardRouteConstants.HOME);
//                   }}
//                 >
//                   Next {'>'}
//                 </Button>
//               </div>
//             </CardActions>
//           </Card>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default About;
