import React, { useState } from 'react';
// import { FaWhatsapp } from 'react-icons/fa';
import { TextField, Button, Typography, Box } from '@mui/material';

const WhatsApp = ({ mobile }) => {
    const [inputNumber, setInputNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const openWhatsApp = () => {
        if (mobile != undefined || mobile != null) {
            const message = `Hello, I am interested in your services. My number is: ${mobile}`;
            const encodedMessage = encodeURIComponent(message);
            const url = `https://api.whatsapp.com/send?phone=${mobile}&text=${encodedMessage}`;
            window.open(url, '_blank');
        } else {
            setErrorMessage('Please enter a valid mobile number');
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <Button
                variant="contained"
                color="primary"
                onClick={openWhatsApp}
                style={{ backgroundColor: '#25D366', color: 'white' }}
            >
                Chat with us on WhatsApp
            </Button>
            {errorMessage && <Typography fontSize={12} color={"red"}>{errorMessage}</Typography>}
        </Box>
    );
};

export default WhatsApp;
