// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signin__leftimg {
  position: relative;
}

.signin__img-overlay {
  position: relative;
}

.signin__img-overlay img {
  display: block;
  width: 100%;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(131, 58, 180, 0.8),
    rgba(58, 123, 213, 0.6)
  );

  /* Adjust the gradient colors and opacity as needed */
  z-index: 1;
  pointer-events: none; /* Ensures the gradient doesn't interfere with image interactions */
}
`, "",{"version":3,"sources":["webpack://./src/containers/Layouts/AuthLayout/overlay.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT;;;;GAIC;;EAED,qDAAqD;EACrD,UAAU;EACV,oBAAoB,EAAE,mEAAmE;AAC3F","sourcesContent":[".signin__leftimg {\n  position: relative;\n}\n\n.signin__img-overlay {\n  position: relative;\n}\n\n.signin__img-overlay img {\n  display: block;\n  width: 100%;\n}\n\n.gradient-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(131, 58, 180, 0.8),\n    rgba(58, 123, 213, 0.6)\n  );\n\n  /* Adjust the gradient colors and opacity as needed */\n  z-index: 1;\n  pointer-events: none; /* Ensures the gradient doesn't interfere with image interactions */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
