import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import SetupLayout from "containers/Layouts/SetupLayout";
import { APP_NAME, APP_LOGO } from "constants/appConstants";
import Billing from "views/InitialSetup/Billing";
import StoreSetup from "./StoreSetup";
import {
    Grid,
    Box,
    StepLabel,
    Stepper,
    Step,
    StepContent
} from "@mui/material";
import Delivery from './Delivery';
import Payment from './Payment';
import { dashboardRouteConstants, APIRouteConstants, authRouteConstants } from 'constants/routeConstants';
import { getAccessToken } from 'utils';
import { updateUserDetail } from 'utils/auth';
import axiosInstance from "configs/axiosConfig";

import Loader from 'components/Loader';

const steps = [
    {
        label: 'Start building your store',
        description: `Enter your store name and add your social media links to vour store.`,
    },
    {
        label: 'Setup your Delivery warehouse',
        description: 'Set up your collection point for delivery partners to collect your products.',
    },
    {
        label: 'Setup payment options',
        description: `Enable payments and set up your tax details for your store.`,
    },
];

const InitialSetup = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [loader, setLoader] = useState(true);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = async () => {
        setLoader(true)
        let res = await axiosInstance.post(APIRouteConstants.WEBSITE.UPDATE_STORE)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            const { user } = loggedInUser
            if (!user['isPlanActive'])
                setBilling(true)
            getUser()
        }
        setLoader(false)
        // window.location.href = dashboardRouteConstants.HOME
    }

    const loggedInUser = useSelector(state => state.userDetails);

    const getUser = async () => {
        let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status == 401) {
            window.location.href = authRouteConstants.LOGOUT
        }

        if (data && data.success) {
            const user = data.data;
            updateUserDetail(user)
        }
    }

    const getLoggedUser = () => {
        if (!_.isEmpty(loggedInUser)) return loggedInUser;
        const token = getAccessToken()
        if (!token) {
            window.location.href = authRouteConstants.HOME
            return
        }
        getUser();
    }

    useEffect(() => {
        getLoggedUser();
    }, []);

    const [storeName, setStoreName] = useState('');
    const [contactDetail, setContacts] = useState({});
    const [hostName, setHostName] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [logo, setLogo] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState();
    const [showBilling, setBilling] = useState();

    const loadWebsiteDetail = async () => {
        let details = {
            hostName: '',
            storeName: '',
            logoUrl: '',
            address: ''
        }
        const getStoreName = async () => {
            let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_ABOUT_US)
            if (res === false) {
                setLoader(false);
                return
            }
            let { data } = res;

            if (data) {
                details.storeName = data['store_name']
                setStoreName(data['store_name'])
            }
        }

        const getLogo = async () => {
            let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_LOGO_BANNER)
            if (res === false) {
                setLoader(false);
                return
            }
            let { data } = res;

            if (data && data['logo']) {
                details.logoUrl = data['logo']
                setLogo([data['logo']])
                setLogoUrl(data['logo'])
            }
        };

        const getContacts = async () => {
            let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_CONTACTS)
            if (res === false) {
                setLoader(false);
                return
            }
            let { data } = res;

            if (data) {
                setContacts(data || [])
            }
        };

        const getWebDomain = async () => {
            let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_DOMAIN)
            if (res === false) {
                setLoader(false);
                return
            }
            let { data } = res;

            if (data && data['host_name']) {
                details.hostName = data['host_name']
                setHostName(data['host_name'])
            }
        };

        const getDeliveryAddress = async () => {
            let res = await axiosInstance.get(APIRouteConstants.STORE.GET_DELIVERY_LOCATION)
            if (res === false) {
                setLoader(false);
                return
            }
            let { data } = res;

            if (!_.isEmpty(data) && !_.isEmpty(data.address_line1)) {
                details.address = data;
                setDeliveryAddress(data)
            }
        };

        await getStoreName()
        await getLogo()
        await getContacts()
        await getWebDomain()
        await getDeliveryAddress()

        if ((
            details.storeName &&
            details.hostName &&
            details.logoUrl) && !details.address) {
            setActiveStep(1)
        }

        if ((
            details.storeName &&
            details.hostName &&
            details.logoUrl) && details.address) {
            setActiveStep(2)
        }

        setLoader(false)
    }

    useEffect(() => {
        if (!_.isEmpty(loggedInUser)) {
            const { user } = loggedInUser
            if (user['storeSetup'] && !user['isPlanActive']) {
                setBilling(true)
                setLoader(false)
            } else if (user['storeSetup'] && user['isPlanActive'])
                window.location.href = dashboardRouteConstants.HOME
            else
                loadWebsiteDetail()
        }
    }, [loggedInUser])

    return (
        <SetupLayout appTitle={APP_NAME}>
            <Loader open={loader} />
            {showBilling ? <Billing /> :
                <Grid container>
                    {/* <Billing /> */}
                    <Grid item lg={9} order={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                        {!loader ?
                            activeStep == 0 ?
                                <StoreSetup store={{
                                    storeName: storeName,
                                    hostName: hostName,
                                    contactDetail: contactDetail,
                                    logo: logo,
                                    logoUrl: logoUrl,
                                    setLogo: setLogo
                                }} handleNext={handleNext} />
                                : activeStep == 1 ?
                                    <Delivery
                                        address={deliveryAddress}
                                        handleNext={handleNext}
                                        handleBack={handleBack} />
                                    : activeStep == 2 ?
                                        <Payment handleBack={handleBack} handleFinish={handleFinish} />
                                        : ''
                            : ''}
                    </Grid>
                    <Grid item lg={3} className='stepper-wrapper' order={{ lg: 2, md: 1, sm: 1, xs: 1 }}>
                        <Box sx={{ maxWidth: 400 }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel>
                                            <span className='title'>{step.label}</span>
                                        </StepLabel>
                                        <StepContent>
                                            <span className='description'>{step.description}</span>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Grid>
                </Grid>}
        </SetupLayout>
    )
}

export default InitialSetup