import { useEffect, useState } from "react";
import { useParams } from "react-router";

import AuthLayout from "containers/Layouts/AuthLayout";
import { APP_NAME, APP_LOGO } from "constants/appConstants";
import Login from "containers/Login";
import SignUp from "containers/SignUp";
import Logout from "containers/Logout";
import ForgotPassword from "containers/Forgotpassword";
import Loginv1 from "views/Landing/Loginv1";
import Signupv1 from "views/Landing/Signupv1";

const Authenticate = () => {
  const params = useParams();
  const { page } = params;
  const [isLogin, setLogin] = useState(false);
  const [isSignup, setSignup] = useState(false);
  const [isLogout, setLogout] = useState(false);
  const [isForgot, setIsForgot] = useState(false);

  useEffect(() => {
    if (page == "login") {
      setLogin(true);
      setSignup(false);
      setIsForgot(false);
      setLogout(false);
    } else if (page == "signup") {
      setLogin(false);
      setSignup(true);
      setIsForgot(false);
      setLogout(false);
    } else if (page == "forgot-password") {
      setLogin(false);
      setSignup(false);
      setLogout(false);
      setIsForgot(true);
    } else {
      setLogin(false);
      setSignup(false);
      setLogout(true);
      setIsForgot(false);
    }
  }, [page]);

  return (
    <AuthLayout page={page} appTitle={APP_NAME}>
      {isLogin && <Login />}
      {isSignup && <SignUp />}
      {isLogout && <Logout />}
      {isForgot && <ForgotPassword />}
    </AuthLayout>
  );
};

export default Authenticate;
