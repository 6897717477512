import { useState } from 'react';
import _ from 'lodash';

import {
    Card,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    TextField,
    CardActions,
    CardContent,
    Select,
    MenuItem,
    InputLabel
} from '@mui/material';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Loader from 'components/Loader';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from "constants/routeConstants";

const NewCategory = ({ discardNewCategory, allCategories }) => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const addCategory = async (formData) => {
        setLoader(true);

        if (!formData.parent) delete formData['parent']

        let res = await axiosInstance.post(APIRouteConstants.STORE.ADD_CATEGORY, formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }

        if (data) {
            enqueueSnackbar('Category added successfully', { variant: 'success' });
            discardNewCategory(false);
        }
        setLoader(false);
    };

    return (
        <Grid container className='component-grid'>
            <Loader open={loader} />
            <Grid item xs={12}>
                <Card className='dashboard-card-wrapper'>
                    <form onSubmit={handleSubmit(addCategory)}>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Categories {`>`} New Category</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    Categories organise your products in a way that makes it easier for your customers to find what they are looking for. Group your products into their respective categories here.
                                </span>
                            </div>
                            <Grid container className='dashboard-form-container' spacing={4} wrap='wrap'>
                                {/* <Add /> */}
                                <Grid item md={6} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Category name
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Define the category your products will belong to.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                placeholder="Category Name"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                {...register("name", { required: true })}
                                                error={errors.name?.type}
                                                helperText={errors.name?.type === "required" && (
                                                    'Category name is required'
                                                )}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12}></Grid>
                                <Grid item md={6} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Description
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Enter the full category description below.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="description"
                                                placeholder="Category Description"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                rows={3}
                                                {...register("description", { required: true })}
                                                error={errors.description?.type}
                                                helperText={errors.description?.type === "required" && (
                                                    'Category description is required'
                                                )}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={12}></Grid>
                                {allCategories.length > 0 ?
                                    <Grid item md={6} sm={12}>
                                        <div className='form-wrapper'>
                                            <FormLabel className='form-label'>
                                                <span className='label-text'>
                                                    Set as subcategory
                                                </span><br />
                                                <span className='label-sec-text'>
                                                    Choose a parent category to make this category a sub.
                                                </span>
                                            </FormLabel>
                                            <FormControl fullWidth className='select-wrapper'>
                                                <InputLabel id="demo-simple-select-label" size="small">Category</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    {...register("parent")}
                                                    label="Status"
                                                    size="small"
                                                >
                                                    {allCategories.map((category, i) =>
                                                        <MenuItem value={category.id}>{category.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                <Button variant="contained" color="error" onClick={() => discardNewCategory(false)}>
                                    Discard
                                </Button>
                                <Button variant="contained" type="submit" color="secondary">
                                    Save Category
                                </Button>
                            </div>
                        </CardActions>
                    </form>
                </Card>
            </Grid>
        </Grid>
    )
}

export default NewCategory