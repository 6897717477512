import React from 'react';
import { Modal, Grid, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PreviewModal = ({ isPreviewModalOpen, handleClose, headerbg,headertext,footerbg,footertext }) => {
  return (
    <Modal open={isPreviewModalOpen} onClose={handleClose} style={{margin:5}}>
      <Grid container direction="column" justifyContent="space-between"  style={{height:"100vh", background:"rgb(0,0,0,0.95)"}}>
        
        {/* Header */}
        <AppBar position="static"  >
          <Toolbar sx={{display:"flex", background:headerbg, alignItems:"center",justifyContent:"space-between"}}>
            <Typography variant="h6" style={{color:headertext}}>
              Header Content
            </Typography>
            <IconButton edge="end" color="inherit" sx={{filter:"invert(1)"}} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* Main Content */}
        <Grid item xs>
          <Typography variant="body1" style={{ padding: '20px' }}>
            Main content goes here
          </Typography>
        </Grid>

        {/* Footer */}
        <AppBar position="static" style={{background:footerbg}}>
          <Toolbar>
            <Typography variant="body2" style={{ margin: 'auto' ,color:footertext}}>
              Footer Content
            </Typography>
          </Toolbar>
        </AppBar>

      </Grid>
    </Modal>
  );
};

export default PreviewModal;
