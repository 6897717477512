import React, { useState, useEffect } from "react";

import { Typography, Grid } from "@mui/material";

import {
    Experimental_CssVarsProvider as CssVarsProvider,
    experimental_extendTheme as extendTheme,
} from "@mui/material/styles";

const defaultTheme = extendTheme();

const SetupLayout = (props) => {
    const { appTitle, page } = props;

    return (
        <CssVarsProvider theme={defaultTheme}>
            <div className="setup-layout-wrapper">
            <Grid container className="setup-layout-container">
                <Grid item md={12}>
                    <div className="logo-wrapper">
                        <div className="img-wrapper">
                            <img
                                src={require("../../../assets/images/logo.png")}
                                alt="logo"
                            />
                        </div>
                        <Typography
                            variant="h4"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                // display: { xs: "none", md: "flex" },
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            {appTitle}
                        </Typography>
                    </div>
                </Grid>
                <Grid item md={12}>
                    {props.children}
                </Grid>
            </Grid>
            </div>
        </CssVarsProvider>
    );
};

export default SetupLayout;