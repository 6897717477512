import React, { useEffect, useState } from "react";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
function Header() {
  const [showMenu, setShowMenu] = useState("");
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/") {
      const script = document.createElement("script");
      script.src =
        "//cdn.cookie-script.com/s/1d15f2aa4f2c7826ad27c0407b4a80b3.js";
      script.charset = "UTF-8";
      script.id = "cookie-script";
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [location.pathname]);

  return (
    <div class="header__navbar">
      <div class="webbie_container">
        <div class="header_logo">
          <Link to={"/"} class="homepage__navbar__container__logo__image">
            <img src="images/LogoImg.svg" alt="" srcset="" />
          </Link>
        </div>
        <ul class="webbie_container_container__navitems">
          <li>
            <Link to={"/about"}>ABOUT</Link>
          </li>
          <li>
            <Link to={"/shipping"}>SHIPPING</Link>
          </li>
          <li>
            <Link to={"/payments"}>PAYMENTS</Link>
          </li>
          <li>
            <Link to={"/faq"}>FAQs</Link>
          </li>
          <li>
            <Link to={"/pricing"}>PRICING</Link>
          </li>
        </ul>
        <div class="homepage__navbar__container__navitems_login">
          <div>
            <Link to={"/auth/login"}>LOGIN</Link>
          </div>
          <div style={{ display: "flex" }}>
            <Link style={{ color: "#fff" }} to={"/auth/signup"}>
              Get started — it's free
            </Link>
          </div>
          {/* <Button
            variant="contained"
            size="medium"
            sx={{
              textTransform: "capitalize",
              fontSize: 10,
              background: "#dd4498",
              "&:hover": {
                background: "#dd4498", // The color you want on hover
              },
              "@media (max-width: 768)": {
                // Adjust the max-width as per your design
                padding: 5,
                width: "100%", // Make the button full-width on smaller screens
              },
            }}
          >
            <Link style={{ color: "#fff" }} to={"/auth/signup"}>
              Get started — it's free
            </Link>
          </Button> */}
        </div>
        <div class="homepage__navbar__hamburger" onClick={handleShowMenu}>
          <i class="bi bi-list"></i>
        </div>
        <div
          class={`homepage__navbar__hamburger-menu ${showMenu ? "show" : ""}`}
          id="menu"
        >
          <ul>
            <li>
              <Link to={"/about"}>ABOUT</Link>
            </li>
            <li>
              <Link to={"/shipping"}>SHIPPING</Link>
            </li>
            <li>
              <Link to={"/payments"}>PAYMENTS</Link>
            </li>
            <li>
              <Link to={"/contact"}>CONTACT</Link>
            </li>
            <li>
              <Link to={"/pricing"}>PRICING</Link>
            </li>
            <li>
              <Link to={"/auth/signup"}>Get started — it's free</Link>
            </li>
            <li>
              <Link to={"/auth/login"}>
                <Button
                size="small"
                  sx={{
                    backgroundColor: "#CD1479",
                    color:"#fff",
                    "&:hover": {
                      backgroundColor: "#CD1479", 
                      color:"#fff",
                    },
                  }}
                >
                  Login
                </Button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
