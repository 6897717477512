import React, { Suspense, lazy } from "react";
import {
  // Route,
  // Routes,
  useRoutes,
} from "react-router-dom";

//import { isAuthenticated } from "../middlewares/auth.middleware";

// import Landing from "views/Landing";
import Authenticate from "views/Authenticate";

import "../styles/app.global.scss";
import "../assets/styles/css/App.css";
// import About from "views/About";
import Dashboard from "views/Dashboard";
import Home from "views/Dashboard/Home";
import Domains from "views/Dashboard/Website/Domains";
import NaN from "views/Dashboard/NaN";
import Analytics from "views/Dashboard/Store/Analytics";
import Products from "views/Dashboard/Store/Products";
import Template from "views/Dashboard/Website/Template";
import Contact from "views/Dashboard/Website/Contact";
import About from "views/Dashboard/Website/About";
import TermsAndCondition from "views/Dashboard/Website/TermsAndCondition";
import Categories from "views/Dashboard/Store/Categories";
import Customers from "views/Dashboard/Store/Customers";
import Orders from "views/Dashboard/Store/Orders";
import Vouchers from "views/Dashboard/Store/Vouchers";
import Payments from "views/Dashboard/Store/Payments";
import Reviews from "views/Dashboard/Store/Reviews";
import Delivery from "views/Dashboard/Store/Delivery";
import Billing from "views/Dashboard/Account/Billing";
import Profile from "views/Dashboard/Account/Profile";
import {
  authRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import InitialSetup from "views/InitialSetup";
import ContactUsv1 from "views/Landing/ContactUsv1";
import Pricingv1 from "views/Landing/Pricingv1";
import Aboutv1 from "views/Landing/Aboutv1";
import Privacypolicyv1 from "views/Landing/Privacypolicyv1";
import Termasandcondistion from "views/Landing/Termasandcondistion";
import Paymentsv1 from "views/Landing/Paymentsv1";
import Partnerv1 from "views/Landing/Partnerv1";
import Shippingv1 from "views/Landing/Shippingv1";
import Loader from "components/Loader";
import ShippingMethod from "views/Dashboard/Store/Shipping/ShippingMethod";
import Tutorial from "views/Dashboard/Tutorial/Tutorial";
import CustomTemplate from "views/Dashboard/Website/CustomTemplate";
import PrivacyPolicy from "views/Dashboard/Website/PrivacyPolicy";
import RefundPolicy from "views/Dashboard/Website/RefundPolicy/RefundPolicy";
import FAQV1 from "views/Landing/FAQV1";

const Landing = lazy(() => import("views/Landing"));
const Loading = () => <div>Loading...</div>;
// eslint-disable-next-line
export default () => {
  let element = useRoutes([
    {
      path: authRouteConstants.HOME,
      element: (
        <Suspense fallback={<Loader />}>
          <Landing />
        </Suspense>
      ),
    },
    {
      path: authRouteConstants.CONATCT,
      element: <ContactUsv1 />,
    },
    {
      path: authRouteConstants.FAQ,
      element: <FAQV1 />,
    },
    {
      path: authRouteConstants.PRICING,
      element: <Pricingv1 />,
    },
    {
      path: authRouteConstants.ABOUT,
      element: <Aboutv1 />,
    },
    {
      path: authRouteConstants.PRIVACYPOLICYV1,
      element: <Privacypolicyv1 />,
    },
    {
      path: authRouteConstants.TERMSANDCONDISTION,
      element: <Termasandcondistion />,
    },
    {
      path: authRouteConstants.PAYMENTS,
      element: <Paymentsv1 />,
    },
    {
      path: authRouteConstants.PARTNERS,
      element: <Partnerv1 />,
    },
    {
      path: authRouteConstants.SHIPPING,
      element: <Shippingv1 />,
    },
    {
      path: `${authRouteConstants.AUTH}/:page`,
      element: <Authenticate />,
    },
    {
      path: dashboardRouteConstants.HOME,
      element: <Dashboard />,
      children: [
        {
          path: dashboardRouteConstants.HOME,
          element: (
            <Home
              breadcrumbs={[
                {
                  path: dashboardRouteConstants.HOME,
                  name: "Home",
                },
              ]}
            />
          ),
        },
        {
          path:"/dashboard/tutorial",
          element: (
            <Tutorial
            />
          ),
        },
      ],
    },
    {
      path: dashboardRouteConstants.WEBSITE,
      element: <Dashboard />,
      children: [
        {
          path: "/website/analytics",
          element: <NaN />,
        },
        {
          path: "/website/templates",
          element: (
            <Template
              breadcrumbs={[
                {
                  path: "/website/templates",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/customtemplates",
          element: (
            <CustomTemplate
              breadcrumbs={[
                {
                  path: "/website/customtemplates",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/domain",
          element: (
            <Domains
              breadcrumbs={[
                {
                  path: "/website/templates",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/contact",
          element: (
            <Contact
              breadcrumbs={[
                {
                  path: "/website/templates",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/about",
          element: (
            <About
              breadcrumbs={[
                {
                  path: "/website/templates",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/terms-conditions",
          element: (
            <TermsAndCondition
              breadcrumbs={[
                {
                  path: "/website/templates",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/returnandrefunds",
          element: (
            <PrivacyPolicy
              breadcrumbs={[
                {
                  path: "/website/returnandrefunds",
                  name: "Website",
                },
              ]}
            />
          ),
        },
        {
          path: "/website/refundpolicy",
          element: (
            <RefundPolicy
              breadcrumbs={[
                {
                  path: "/website/refundpolicy",
                  name: "Website",
                },
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "/store",
      element: <Dashboard />,
      children: [
        {
          path: "/store/analytics",
          element: (
            <Analytics
              breadcrumbs={[
                {
                  path: "/store/analytics",
                  name: "Store",
                },
              ]}
            />
          ),
        },
        {
          path: "/store/products",
          element: (
            <Products
              breadcrumbs={[
                {
                  path: "/store/products",
                  name: "Store",
                },
              ]}
            />
          ),
        },
        {
          path: "/store/categories",
          element: (
            <Categories
              breadcrumbs={[
                {
                  path: "/store/products",
                  name: "Store",
                },
              ]}
            />
          ),
        },
        // {
        //   path: "/store/customers",
        //   element: (
        //     <Customers
        //       breadcrumbs={[
        //         {
        //           path: "/store/products",
        //           name: "Store",
        //         },
        //       ]}
        //     />
        //   ),
        // },
       
        {
          path: "/store/payments",
          element: (
            <Payments
              breadcrumbs={[
                {
                  path: "/store/products",
                  name: "Store",
                },
              ]}
            />
          ),
        },
        {
          path: "/store/shipping",
          element: (
            <ShippingMethod
              breadcrumbs={[
                {
                  path: "/store/shipping",
                  name: "Store",
                },
              ]}
            />
          ),
        },
        {
          path: "/store/delivery",
          element: (
            <Delivery
              breadcrumbs={[
                {
                  path: "/store/products",
                  name: "Store",
                },
              ]}
            />
          ),
        },
        {
          path: "/store/reviews",
          element: (
            <Reviews
              breadcrumbs={[
                {
                  path: "/store/products",
                  name: "Store",
                },
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "/reports",
      element: <Dashboard />,
      children: [
        {
          path: "/reports/orders",
          element: (
            <Orders
              breadcrumbs={[
                {
                  path: "/reports/orders",
                  name: "Reports",
                },
              ]}
            />
          ),
        },
        {
          path: "/reports/customers",
          element: (
            <Customers
            breadcrumbs={[
              {
                path: "/reports/products",
                name: "Reports",
              },
            ]}
          />
          ),
        },
        
      ],
    },
    {
      path: "/marketing",
      element: <Dashboard />,
      children: [
        // {
        //   path: "/marketing/orders",
        //   element: (
        //     <Orders
        //       breadcrumbs={[
        //         {
        //           path: "/marketing/orders",
        //           name: "Marketing",
        //         },
        //       ]}
        //     />
        //   ),
        // },
        {
          path: "/marketing/vouchers",
          element: (
            <Vouchers
              breadcrumbs={[
                {
                  path: "/marketing/vouchers",
                  name: "Marketing",
                },
              ]}
            />
          ),
        },
        
      ],
    },
    {
      path: "/account",
      element: <Dashboard />,
      children: [
        {
          path: "/account/details",
          element: (
            <Profile
              breadcrumbs={[
                {
                  path: "/account/details",
                  name: "Account",
                },
              ]}
            />
          ),
        },
        {
          path: "/account/billing",
          element: (
            <Billing
              breadcrumbs={[
                {
                  path: "/account/details",
                  name: "Account",
                },
              ]}
            />
          ),
        },
        {
          path: "/account/store",
          element: <NaN />,
        },
      ],
    },
    {
      path: dashboardRouteConstants.INITIAL_SETUP,
      element: <InitialSetup />,
    },
    {
      path: "/logout",
      element: <NaN />,
    },
    {
      path: "*",
      element: <NaN />,
    },
  ]);
  return element;
};
