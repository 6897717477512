import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    CardContent,
    CardActions,
    TablePagination
} from '@mui/material';
import moment from 'moment';

import SearchInput from 'components/Search';
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import BasicTable from 'components/Tables/BasicTable';

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";

const Customers = ({ breadcrumbs }) => {
    const [loader, setLoader] = useState(false);
    const [allCustomers, setAllCustomers] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchString, setSearchString] = useState('');

    const getCustomerList = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_CUSTOMERS, {
            params: {
                page: pageCount + 1,
                search: searchString
            }
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data && data.results) {
            setAllCustomers(data.results)
            setTotalCount(data.count);
            setPageCount(data.current_page - 1);
        }
        setLoader(false)
    }

    useEffect(() => {
        getCustomerList()
    }, [pageCount])

    const handleChangePage = (e, page) => {
        setPageCount(page);
    }

    return (
        <div className="website-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"Customers"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>Customers Details</span>
                            </div>
                            <Divider />
                            <div className='filter-bar-wrapper content-wrapper content-flex'>
                                <span className='content-text'>
                                    Here you will find a complete history of all of your customers. You can view an individual customer’s details as well as their order history by clicking on the ‘Manage customer’ button. You can also search for customers by customer name or email address.
                                </span>
                                <SearchInput
                                    value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value)
                                    }}
                                    onEnter={getCustomerList}
                                    onBlur={getCustomerList}
                                />
                            </div>
                            {allCustomers.length > 0 ?
                                <Grid container className='dashboard-table-container' spacing={4} wrap='wrap'>
                                    <Grid item md={12} sm={12}>
                                        <BasicTable
                                            rows={allCustomers}
                                            headers={[{
                                                label: 'Customer Name',
                                                type: 'custom',
                                                width: '20%',
                                                arguments: ['first_name', 'last_name'],
                                                component: (firstName, lastName) => {
                                                    return (
                                                        <div className='rating-wrapper'>
                                                            <span>{`${firstName} ${lastName}`}</span>
                                                        </div>
                                                    )
                                                }
                                            }, {
                                                label: 'Email',
                                                width: '30%',
                                                name: 'email'
                                            }, {
                                                label: 'Username',
                                                name: 'username',
                                                width: '10%',
                                            }, {
                                                label: 'Phone Number',
                                                type: 'custom',
                                                arguments: ['country_code', 'mobile_number'],
                                                component: (code, phoneNumber) => {
                                                    return (
                                                        <div className='rating-wrapper'>
                                                            <span>{`${code}${phoneNumber}`}</span>
                                                        </div>
                                                    )
                                                },
                                                width: '15%'
                                            }, {
                                                label: 'User Since',
                                                type: 'custom',
                                                arguments: ['date_joined'],
                                                component: (joiningDate) => {
                                                    return (
                                                        <div className='rating-wrapper'>
                                                            <span>{moment(joiningDate).format("ddd[,] D MMM")}</span>
                                                        </div>
                                                    )
                                                },
                                                width: '10%',
                                            }, {
                                                label: 'Last Login',
                                                type: 'custom',
                                                arguments: ['last_login'],
                                                component: (lastLogin) => {
                                                    return (
                                                        <div className='rating-wrapper'>
                                                            {lastLogin ?
                                                                <span>{moment(lastLogin).format("[On] ddd[,] D MMM")}</span>
                                                                : ''}
                                                        </div>
                                                    )
                                                },
                                                width: '15%',
                                            }]}
                                        />
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={totalCount < 10 ? totalCount : 10}
                                            page={pageCount}
                                            onPageChange={handleChangePage}
                                        />
                                    </Grid>
                                </Grid>
                                : <div className='content-wrapper content-flex content-flex-center'>
                                    <NoData />
                                    <div className='detail-action-wrapper content-flex content-flex-center'>
                                        <span className='detail-sec-text'>
                                            You have no customers yet
                                        </span>
                                    </div>
                                </div>}
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                {/* <Button variant="contained" color="error" onClick={() => discardNewProduct(false)}>
                                Discard
                            </Button>
                            <Button variant="contained" color="secondary">
                                Save Product
                            </Button> */}
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Customers