import { useState, useEffect, Children, cloneElement } from "react";
import _ from "lodash";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import "./overlay.css";
import "../../../views/Landing/formstyle.css";
import "../../../views/Landing/globalstylesheet-sai.css";
import "../../../views/Landing/globalstylesheet.css";
import Backicon from "../../../assets/images/images/LogoImg.svg";
import Googleicon from "../../../assets/images/images/google-icon.svg";
import Hideicon from "../../../assets/images/logo-title.png";
import SideLogo from "../../../assets/images/images/login-img1.png";
import SideLogoCover from "../../../assets/images/images/homebanner-bg-1.svg";
import HomeIcon from "@mui/icons-material/Home";
import { ReactComponent as Auth } from "assets/svgs/Auth.svg";
import { authRouteConstants } from "constants/routeConstants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "constants/appConstants";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "views/Landing/Footer";

const defaultTheme = extendTheme();

const AuthLayout = (props) => {
  const { appTitle, page } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [openTermsAndCondition, setOpenTermsAndCondition] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState();
  const [privacyData, setPrivacyData] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (openPrivacyPolicy && _.isEmpty(privacyData)) getPrivacyPolicy();
  }, [openPrivacyPolicy]);

  useEffect(() => {
    if (openTermsAndCondition && _.isEmpty(termsAndCondition))
      getTermsAndCondition();
  }, [openTermsAndCondition]);

  const getPrivacyPolicy = async () => {
    let res = await axios.get(PRIVACY_POLICY);
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }

    if (data && data.data && data.data.other_content_blocks) {
      setPrivacyData(data.data.other_content_blocks[0]);
    }
  };

  const getTermsAndCondition = async () => {
    let res = await axios.get(TERMS_OF_SERVICE);
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }

    if (data && data.data && data.data.other_content_blocks) {
      setTermsAndCondition(data.data.other_content_blocks[0]);
    }
  };

  const renderChildren = () => {
    return Children.map(props.children, (child) => {
      return child
        ? cloneElement(child, {
            setOpenPrivacyPolicy: setOpenPrivacyPolicy,
            setOpenTermsAndCondition: setOpenTermsAndCondition,
          })
        : "";
    });
  };
  useEffect(() => {
    const removeScript = (src) => {
      const scripts = document.querySelectorAll(`script[src="${src}"]`);
      scripts.forEach((script) => script.remove());
    };

    removeScript(
      "//cdn.cookie-script.com/s/1d15f2aa4f2c7826ad27c0407b4a80b3.js"
    );
  }, []);
  return (
    <CssVarsProvider theme={defaultTheme}>
      <section className="signin">
        <div className="signin_child">
          <div className="signin__right__top">
            <Link to={"/"}>
              <div style={{ width: "100%" }}>
                <img src={Backicon} alt="backicon" style={{ width: "100%" }} />
              </div>
            </Link>
            {page == "login" ? (
              <div className="signin__right__top__rightcontent">
                Not a member yet?{" "}
                <Link to={"/auth/signup"} style={{ paddingLeft: "5px" }}>
                  JOIN NOW
                </Link>
              </div>
            ) : (
              <div className="signin__right__top__rightcontent">
                Already have an account?{"   "}
                <Link to={"/auth/login"} style={{ paddingLeft: "5px" }}>
                  SIGN IN
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="signin_child1">
          {/* <div className="signin__leftimg">
            <img src={SideLogoCover} alt="" style={{ width: "1005" }} />
            <img src={SideLogo} alt="" style={{ width: "100%" }} />
          </div> */}
          <div className="signin__leftimg">
            <div className="signin__img-overlay">
              <img
                src={SideLogo}
                alt=""
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div className="gradient-overlay"></div>
          </div>

          <article className="signin__right">
            <div
              className="auth-layout-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {/* <div className="signin__right__top__rightcontent">
                Not a member yet? <Link to={"/auth/signup"}>JOIN NOW</Link>
              </div> */}
              <Grid item md={12} lg={4}>
                {renderChildren()}
              </Grid>
            </div>
          </article>
        </div>
        <Footer />
      </section>

      {
        <Dialog
          className="condition-dialog-wrapper"
          onClose={() => {
            setOpenTermsAndCondition(false);
            setOpenPrivacyPolicy(false);
          }}
          open={openPrivacyPolicy || openTermsAndCondition}
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "1.2rem" }}>
            {_.get(privacyData, "heading", "Privacy Policy")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenTermsAndCondition(false);
              setOpenPrivacyPolicy(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <DialogContent className="dialog-content">
            <DialogContentText
              className="policy-wrapper"
              id="alert-dialog-slide-description"
            >
              <span style={{ fontWeight: "600", color: "black" }}>
                {openPrivacyPolicy ? _.get(privacyData, "subheading") : ""}
                {openTermsAndCondition
                  ? _.get(termsAndCondition, "subheading")
                  : ""}
              </span>
              <p style={{ whiteSpace: "pre-line" }}>
                {openPrivacyPolicy ? _.get(privacyData, "text_content") : ""}
                {openTermsAndCondition
                  ? _.get(termsAndCondition, "text_content")
                  : ""}
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action">
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => {
                setOpenTermsAndCondition(false);
                setOpenPrivacyPolicy(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </CssVarsProvider>
  );
};

export default AuthLayout;

{
  /* <div className="auth-layout-wrapper">
<Grid container className="auth-layout-container">
  <Grid item md={12} lg={8}>
    <div className="logo-wrapper">
      <div
        onClick={() => navigate(authRouteConstants.HOME)}
        style={{ cursor: "pointer" }}
        className="img-wrapper"
      >
        <img
          src={require("../../../assets/images/logo-title.png")}
          alt="logo"
        />
      </div>
      <div>
        <p>back to Home</p>
      </div>
    </div>
    {page == "login" ? (
      <div className="content-wrapper">
        <span className="title">Sell online with Webbie Shop</span>
        <br />
        <br />
        <span className="description">
          Webbie is a full-featured and affordable ecommerce solution
          that includes web, mobile and social stores.
        </span>
      </div>
    ) : (
      <div className="content-wrapper">
        <span className="title">Launch your business in 3, 2, 1…</span>
        <br />
        <br />
        <span className="description">
          Build, manage, market - All at one place.
        </span>
      </div>
    )}
    <div className="svg-wrapper">
      <Auth />
      {/* 
      <img src={SideLogo}></img> */
}
//     </div>
//   </Grid>
//   <Grid item md={12} lg={4}>
//     {renderChildren()}
//   </Grid>
// </Grid>
// </div> */}
