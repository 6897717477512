import { useState, useEffect } from 'react';

import {
    Card,
    Divider,
    Grid,
    Button,
    CardContent,
    CardActions,
    Switch,
    Collapse,
    FormLabel,
    FormControl,
    TextField
} from '@mui/material';
import _ from 'lodash';

import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import FormDialog from 'components/Dialog/FormDialog';
import { enqueueSnackbar } from 'notistack';

const Payment = ({ handleBack, handleFinish }) => {
    const [loader, setLoader] = useState(true);
    const [allPaymentModes, setPaymentModes] = useState([]);
    const [peachCredential, setPeachCredential] = useState({});
    const [isPaymentModeAvailable, setPaymentModeAvailable] = useState(false);
    const [isPaymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [paymentFormData, setFormData] = useState({
        entityId: '',
        userName: '',
        password: ''
    });

    const getPaymentModes = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_PAYMENT_MODES)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data) {
            setPaymentModes(data)
            setPaymentModeAvailable(Boolean(_.find(data, { status: true })))
        }
        setLoader(false)
    }

    const updatePaymentMode = async (paymentModeId, status) => {
        setLoader(true)
        console.log('Update riggering ', paymentModeId)
        let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_PAYMENT_MODE(paymentModeId), {
            status: status
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        console.log('Update response ', data, response)

        if (data) {
            getPaymentModes();
        }
    }

    const getPeachPaymentCredentials = async () => {
        setLoader(true);
        let res = await axiosInstance.get(APIRouteConstants.STORE.GET_PEACH_CREDENTIAL)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (!_.isEmpty(data)) {
            setPeachCredential(data[0]);
        }
        showModeDetail(true)
        setLoader(false)
    }

    const addPeachPaymentCredentials = async () => {
        if (
            !paymentFormData.entityId
        ) {
            enqueueSnackbar('Please provide all mandatory details', { variant: 'error' });
            return;
        }
        setLoader(true);
        let res = await axiosInstance.post(APIRouteConstants.STORE.SET_PEACH_CREDENTIAL, {
            username: paymentFormData.userName,
            entity_id: paymentFormData.entityId,
            password: paymentFormData.password
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i][0], { variant: 'error' });
                setLoader(false)
            }
        }

        if (!_.isEmpty(data)) {
            setPaymentDialogOpen(false);
            getPeachPaymentCredentials();
            getPaymentModes()
        }
        showModeDetail(true)
    }

    // {
    //     "entity_id": "8ac7a4c98b22d759018b2321b69a00b9",
    //     "username": "a64b5fc263794a6791d4c32df6bba207",
    //     "password": "rJBG0SaYnWhfdXvABmN68kjfvGt92cN1"
    // "access_key":"zjhgfdswertyuuuihjhbccdsaas"
    // }

    useEffect(() => {
        getPaymentModes()
        getPeachPaymentCredentials()
    }, [])

    const [modeDetail, showModeDetail] = useState(false);

    return (
        <div className="setup-wrapper website-analytics-wrapper">
            <Loader open={loader} />
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='list-wrapper dashboard-card-wrapper'>
                        <CardContent>
                            <div className='header-wrapper'>
                                <span className='header-text'>What payment options would you like for your customers?</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper content-flex'>
                                <span className='content-text'>
                                    To start accepting payments on your online store, you need to set up the payment method(s) you prefer to use for your business. If you do not currently have an account with the provider, you can skip this step and then set this up later.
                                </span>
                            </div>
                            <div className='content-wrapper content-flex'>
                                <Grid container className='filter-wrapper'>
                                    {allPaymentModes.length > 0 ? allPaymentModes.map((mode) => (
                                        <Grid item sm={12} className='payment-card'>
                                            <div className='payment-header'>
                                                <span className='detail-sec-text'>
                                                    {mode['payment_method_name']}<br />
                                                    {mode['id'] == 1 ? <span className="label-text"
                                                        onClick={() => {
                                                            if (!modeDetail)
                                                                getPeachPaymentCredentials()
                                                            else {
                                                                showModeDetail(false)
                                                                setPeachCredential()
                                                            }
                                                        }}>
                                                        {modeDetail ? 'Read Less' : 'Read More'}
                                                    </span> : ''}
                                                </span>
                                                <Switch
                                                    checked={mode['status']}
                                                    onChange={() => {
                                                        updatePaymentMode(mode['id'], !mode['status'])
                                                    }}
                                                />
                                            </div>
                                            {mode['id'] == 1 ?
                                                <Collapse in={modeDetail} timeout="auto" unmountOnExit>
                                                    <div className="payment-detail">
                                                        <span>--------------------------------------</span><br />
                                                        <span>Entity Id : {_.get(peachCredential, 'entity_id')}</span><br />
                                                        <span>Username : {_.get(peachCredential, 'username')}</span><br />
                                                        {/* <span>Password : {_.get(peachCredential, 'password')}</span><br /> */}
                                                        <span>Password : xxxxxxx</span><br />
                                                        <span>Access Key : {_.get(peachCredential, 'access_key')}</span><br />
                                                    </div>
                                                </Collapse> : ''}
                                        </Grid>
                                    )) : ''}
                                </Grid>
                            </div>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                {/* <Button variant="contained" color="secondary">
                                Save Changes
                            </Button> */}
                                {/* <Button variant="outlined" onClick={handleBack}>
                                {'<'} Back
                            </Button> */}
                                <Button
                                    type="submit"
                                    variant='contained'
                                    disabled={!isPaymentModeAvailable}
                                    onClick={handleFinish}>
                                    Finish Step
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <FormDialog
                setOpen={() => setPaymentDialogOpen(!isPaymentDialogOpen)}
                viewSave={true}
                viewCancel={true}
                open={isPaymentDialogOpen}
                className="credential-dialog"
                title={"Set Payment Credentials"}
                handleSave={() => addPeachPaymentCredentials()}
            >
                <div className='credential-wrapper'>
                    <Grid container>
                        <Grid xs={12} className='form-wrapper'>
                            <FormLabel className='form-label'>
                                <span className='label-text'>
                                    Entity Id
                                    <span className='required'>*</span>
                                </span>
                            </FormLabel>
                            <FormControl className='form-control'>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    placeholder="Entity Id"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={e => {
                                        setFormData({
                                            ...paymentFormData,
                                            entityId: e.target.value
                                        })
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} className='form-wrapper'>
                            <FormLabel className='form-label'>
                                <span className='label-text'>
                                    Username
                                    {/* <span className='required'>*</span> */}
                                </span>
                            </FormLabel>
                            <FormControl className='form-control'>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    placeholder="Username"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={e => {
                                        setFormData({
                                            ...paymentFormData,
                                            userName: e.target.value
                                        })
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} className='form-wrapper'>
                            <FormLabel className='form-label'>
                                <span className='label-text'>
                                    Password
                                    {/* <span className='required'>*</span> */}
                                </span>
                            </FormLabel>
                            <FormControl className='form-control'>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    placeholder="xxxxxxxxxxxxxx"
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={e => {
                                        setFormData({
                                            ...paymentFormData,
                                            password: e.target.value
                                        })
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </FormControl>
                        </Grid>
                        {/* <Grid xs={12} className='form-wrapper'>
                            <FormLabel className='form-label'>
                                <span className='label-text'>
                                    Access Key
                                </span>
                            </FormLabel>
                            <FormControl className='form-control'>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    placeholder="Access Key"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={e => {
                                        setFormData({
                                            ...paymentFormData,
                                            accessKey: e.target.value
                                        })
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </FormControl>
                        </Grid> */}
                    </Grid>
                </div>
            </FormDialog>
        </div>
    )
}

export default Payment