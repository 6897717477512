import React from "react";
import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import Backicon from "../../assets/images/images/backicon.svg";
import Googleicon from "../../assets/images/images/google-icon.svg";
import Hideicon from "../../assets/images/images/Hide-Icon.svg";
import { Link } from "react-router-dom";

function Signupv1() {
  return (
    <section class="signup">
      <article class="signup__leftimg">
        <img
          src={require("../../assets/images/images/login-img.png")}
          alt=""
          class="signup__leftimg__img"
        />
        {/* <!-- <img src="images/left-coverimg.png" alt="" class="signup__leftimg__img-cover"> -->
          <!-- <div class="signup__leftimg__textcontainer">
                  <div class="signup__leftimg__textcontainer__heading">
                      <div>Partnership for</div>
                      <div>Business Growth</div>
                  </div>
                  <div class="signup__leftimg__textcontainer__content">
                      Lorem ipsum dolor sit amet consectetur 
                      adipisicing elit. Aut qui molestias architecto cupiditate .
                  </div>
              </div> --> */}
      </article>
      <article class="signup__right">
        <div class="signup__right__top">
          <Link to={"/"} class="signup__right__top__btn">
            <img src={Backicon} alt="backicon" />
            <span>RETURN HOME</span>
          </Link>
          <div class="signup__right__top__rightcontent">
            Already have an account? <Link to={"/auth/login"}>SIGN IN</Link>
          </div>
        </div>
        <form class="signup__right__form">
          <div class="signup__right__form__topcontent">
            <div>Sing up</div>
            <div>Enter your Username and Password to Login</div>
            <div></div>
          </div>
          <div class="signup__right__form__logingooglebtn">
            <button class="signup__right__form__logingooglebtn__btn">
              <img src={Googleicon} alt="" />
              <div>Sign in with Google</div>
              <div>lorem</div>
            </button>
            <div class="signup__right__form__logingooglebtn__content">
              OR SIGNUP WITH EMAIL
            </div>
          </div>
          <div class="signup__right__form__input-fields">
            <div class="signup__right__form__input-fields__input-item">
              <label for="email">EMAIL</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="debra.holt@example.com"
              />
            </div>
            <div class="signup__right__form__input-fields__input-item">
              <label for="confirm_email">CONFIRM EMAIL</label>
              <input
                type="email"
                name="confirm_email"
                id="confirm_email"
                placeholder="debra.holt@example.com"
              />
            </div>
            <div class="signup__right__form__input-fields__input-item password-field">
              <label for="password">PASSWORD</label>
              <input type="password" name="password" id="password" />
              <div class="password-icon">
                <img src={Hideicon} alt="hideicon" />
              </div>
            </div>

            <div class="signup__right__form__input-fields__input-item password-field">
              <label for="password">CONFIRM PASSWORD</label>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
              />
              <div class="password-icon">
                <img src={Hideicon} alt="hideicon" />
              </div>
            </div>
          </div>
          <div class="signup__right__form__signupbtn">
            <button>SIGN UP</button>
          </div>
        </form>
      </article>
    </section>
  );
}

export default Signupv1;
